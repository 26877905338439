import React from 'react'
import Accordion from '../../modules/accordion/Accordion'
import c from './overview.module.scss'
import ProgressBar from './progress-bar/ProgressBar'
import { capitalise } from 'utils/transforms/string-transforms'
import { grades } from 'utils/data/grades.js'
import CreditPointsTag from 'components/planner/mapper/blocks/credit-points-tag/CreditPointsTag'
import Icon from 'components/utilities/icons/Icon'

// TODO: make those lists reusable
// List of non creditable grades
/*const nonCreditableGradeSet = new Set([
  'WH',
  'DEF',
  'NS',
  'N',
  'NH',
  'WN',
  'NSR',
  'WDN',
  'WI',
])*/
const nonCreditableGradeSet = new Set(
  Object.keys(grades).filter((g) => grades[g].result !== 'PASS')
)

// List of creditable unit statuses
const creditableUnitStatusSet = new Set(['ENROLLED', 'COMPLETED', 'DUPLICATE'])
const creditableCreditStatusSet = new Set(['GRANTED'])

// Current year
const currentYear = new Date().getFullYear()

const Overview = ({ pageData, currentCourse }) => {
  const flattenUnitEnrollment = (course) => {
    const teachingPeriods = course.teachingPeriods
    const advancedStandings = course.advancedStandings
    const teachingPeriodsEnrollment = []
    const flatUnitEnrollment = []

    //Add advanced standings to unit enrollment
    if (advancedStandings) {
      Object.values(advancedStandings).forEach((as, i) => {
        if (as) {
          flatUnitEnrollment.push({
            type: as.unitCode ? 'specified credit' : 'unspecified credit',
            unitCode: as.unitCode,
            creditPoints: as.creditPoints,
            unitLevel: as.unitLevel,
            status: as.grantingStatus,
          })
        }
      })
    }

    //Add unit groups to teaching periods enrollment
    if (teachingPeriods) {
      Object.values(teachingPeriods).forEach((units, i) => {
        if (units?.unitEnrolments) {
          teachingPeriodsEnrollment.push(units?.unitEnrolments)
        }
      })
    }

    //Add units to unit enrollment
    teachingPeriodsEnrollment.forEach((units, i) => {
      Object.values(units).forEach((unit, i) => {
        if (unit) {
          flatUnitEnrollment.push({
            type: 'unit',
            unitCode: unit.studyUnit.unitCode,
            creditPoints: unit.studyUnit.maxCreditPoints,
            unitLevel: unit.studyUnit.level,
            status: unit.status,
            grade: unit.grade || unit.duplicateOf?.[0].grade || null,
          })
        }
      })
    })

    return flatUnitEnrollment
  }

  const flatUnitEnrollment =
    currentCourse && flattenUnitEnrollment(currentCourse)

  let unitLevels = []
  flatUnitEnrollment?.forEach((item, i) => {
    //Check unit level already exist and unit or credit is creditable
    if (
      unitLevels.indexOf(item.unitLevel) === -1 &&
      item.unitLevel !== '0' &&
      !nonCreditableGradeSet.has(item.grade) &&
      (creditableUnitStatusSet.has(item.status) ||
        creditableCreditStatusSet.has(item.status))
    ) {
      unitLevels.push(item.unitLevel)
    }
  })

  unitLevels = unitLevels.sort()

  const creditsLevelCount = (level) => {
    let creditPoints = 0
    flatUnitEnrollment?.forEach((item, i) => {
      if (
        item.type === 'unit' &&
        !nonCreditableGradeSet.has(item.grade) &&
        creditableUnitStatusSet.has(item.status)
      ) {
        if (item.unitLevel === level) {
          creditPoints += item.creditPoints || 0
        }
      }
      if (
        (item.type === 'specified credit' || 'unspecified credit') &&
        creditableCreditStatusSet.has(item.status)
      ) {
        if (item.unitLevel === level) {
          creditPoints += item.creditPoints || 0
        }
      }
    })
    return creditPoints
  }

  const notes = currentCourse?.notes?.['_items']
    //latest first
    .sort((a, b) => new Date(b.updateOn) - new Date(a.updateOn))
    //initially show only from past year
    // .filter((note) => {
    //   if (allNotes) return true
    //   const now = new Date()
    //   const noteDate = new Date(note.updateOn)
    //   let months =
    //     (now.getFullYear() - noteDate.getFullYear()) * 12
    //     - noteDate.getMonth()
    //     + now.getMonth()
    //   return months <= 12
    // })
    //group by type
    .sort((a, b) =>
      b.noteType > a.noteType ? 1 : a.noteType > b.noteType ? -1 : 0
    )
    //lay em out
    .map((note) => {
      const header = (
        <span className={c.accordionHeader}>
          {note.noteType && (
            <h2>{capitalise(note.noteType.toLowerCase())} note</h2>
          )}
          {note.updateOn && (
            <span className={c.secondary}>
              Updated on: {new Date(note.updateOn).toLocaleDateString()}
            </span>
          )}
        </span>
      )
      return (
        <Accordion header={header} expanded={false} key={note.id}>
          <div className={c.note}>
            <span>{note.noteText}</span>
          </div>
        </Accordion>
      )
    })

  const renderAdvancedStandings = () =>
    currentCourse.advancedStandings.map((as, i) => (
      <Accordion
        key={i}
        header={
          <span className={c.accordionHeader}>
            <h3>
              {as.unitCode ? as.unitCode + ' \u2013 ' : null}
              {as.unitTitle ||
                `Level ${as.unitLevel} ${capitalise(as.disciplineDescription)}`}
            </h3>
            <label className={c.priorStatusLabel}>
              {capitalise(as.recognitionType)} {'\u2013'}{' '}
              {as.grantingStatus.toLowerCase()}
            </label>
            <CreditPointsTag
              blockType={'CREDIT'}
              origin={'CREDIT'}
              creditPoints={as.creditPoints}
            />
            {as.comments ? <Icon.Notes /> : null}
          </span>
        }
        expanded={false}>
        <div className={c.prior}>
          <div className={c.institution}>
            <label>Institution</label>
            <span>{as.exemptionInstitutionName ?? '\u2013'}</span>
          </div>
          <div className={c.comment}>
            <label>Comment</label>
            {as.comments ?? '\u2013'}
          </div>
        </div>
      </Accordion>
    ))

  //Only credit exceptions in current year or future will be relevant
  const relevantFullYearExceptions = currentCourse.fullYearExceptions?.filter(
    (ex) => ex.academicYear >= currentYear
  )

  const renderFullYearExceptions = () =>
    relevantFullYearExceptions.map((ex, i) => (
      <Accordion
        key={i}
        header={
          <span className={c.accordionHeader}>
            <h3>{`${ex.academicYear} \u2013 ${
              ex.nonEsosPeriodOneMinimum !== undefined ||
              ex.nonEsosPeriodTwoMinimum !== undefined
                ? 'Non-ESOS'
                : 'ESOS Full year'
            }`}</h3>
            <label className={c.fullYearExceptionsReasonLabel}>
              {ex.reasonTypeCode}
            </label>
          </span>
        }
        expanded={false}>
        <div className={c.fullYear}>
          {/* Display both 1st half and 2nd half non ESOS minimum CP if one of the values exist  */}
          {ex.nonEsosPeriodOneMinimum !== undefined ||
          ex.nonEsosPeriodTwoMinimum !== undefined ? (
            <div className={c.creditPoints}>
              <div>
                <label>Minimum {'\u2013'} 1st half</label>
                {ex.nonEsosPeriodOneMinimum !== undefined ? (
                  <CreditPointsTag creditPoints={ex.nonEsosPeriodOneMinimum} />
                ) : (
                  '\u2013'
                )}
              </div>
              <div>
                <label>Minimum {'\u2013'} 2nd half</label>
                {ex.nonEsosPeriodTwoMinimum !== undefined ? (
                  <CreditPointsTag creditPoints={ex.nonEsosPeriodTwoMinimum} />
                ) : (
                  '\u2013'
                )}
              </div>
            </div>
          ) : null}
          {/* Display both ESOS minimum and maximum CP labels if one of the values exist */}
          {ex.esosCreditPointsMinimum !== undefined ||
          ex.esosCreditPointsMaximum !== undefined ? (
            <div className={c.creditPoints}>
              <div>
                <label>Minimum</label>
                {ex.esosCreditPointsMinimum !== undefined ? (
                  <CreditPointsTag creditPoints={ex.esosCreditPointsMinimum} />
                ) : (
                  '\u2013'
                )}
              </div>
              <div>
                <label>Maximum</label>
                {ex.esosCreditPointsMaximum !== undefined ? (
                  <CreditPointsTag creditPoints={ex.esosCreditPointsMaximum} />
                ) : (
                  '\u2013'
                )}
              </div>
            </div>
          ) : null}
          <div>
            <label>Description</label>
            {capitalise(ex.reasonTypeDescription)}
          </div>
          <div>
            <label>Comment</label>
            {ex.comments ? capitalise(ex.comments) : '\u2013'}
          </div>
        </div>
      </Accordion>
    ))

  const courseProgress = () => {
    const progress = currentCourse.progression
    return (
      <>
        <section className={c.courseProgress}>
          <div className={c.sectionContents}>
            <h2>
              Completion progress: {progress.percentCompleted}% ({' '}
              {progress.creditPointsTotalAchieved}/
              {progress.creditPointsRequired} credit points )
            </h2>
            <ProgressBar
              adv={parseInt(progress.creditPointsAdvancedStanding)}
              comp={parseInt(progress.creditPointsPassed)}
              enr={
                parseInt(progress.creditPointsCurrentEnrolled) +
                parseInt(progress.creditPointsFutureEnrolled)
              }
              total={parseInt(progress.creditPointsRequired)}
            />
          </div>
          {unitLevels && (
            <Accordion
              header={<h2>Credit points completed or enrolled per level</h2>}>
              {unitLevels.map((level, i) => {
                return (
                  <p key={i}>
                    Level {level}: {creditsLevelCount(level)}
                  </p>
                )
              })}
            </Accordion>
          )}
        </section>
        {currentCourse.notes?.totalRecords > 0 && (
          <section>
            <Accordion header={<h2> Student course notes</h2>} expanded={false}>
              {notes}
              {/* {!allNotes && notes.length < currentCourse.notes?.totalRecords && (
              <div onClick={() => setAllNotes(true)} className={c.showMore}>
                Show all ...
              </div>
            )} */}
            </Accordion>
          </section>
        )}
        {currentCourse.advancedStandings.length ? (
          <section>
            <Accordion header={<h2>Credit for prior learning</h2>}>
              {renderAdvancedStandings()}
            </Accordion>
          </section>
        ) : null}
        {relevantFullYearExceptions?.length ? (
          <section>
            <Accordion header={<h2>Full year enrolment exceptions</h2>}>
              {renderFullYearExceptions()}
            </Accordion>
          </section>
        ) : null}
      </>
    )
  }

  // render

  return (
    <div className={c.overview}>
      {currentCourse && courseProgress()}
      {!pageData && (
        <section>
          <p>Not Data Found</p>
        </section>
      )}
    </div>
  )
}

export default Overview
