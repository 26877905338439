export const courseLabelColors = [
  {
    light: '#CC69A4',
    dark: '#86456C',
    darkBackground: '#F3ECF0',
  },
  {
    light: '#4BB4CC',
    dark: '#2D6C7A',
    darkBackground: '#EAF0F2',
  },
  {
    light: '#4BB4CC',
    dark: '#2D6C7A',
    darkBackground: '',
  },
  {
    light: '#CC69A4',
    dark: '#86456C',
    darkBackground: '#F3ECF0',
  },
  {
    light: '#4BB4CC',
    dark: '#2D6C7A',
    darkBackground: '#EAF0F2',
  },
  {
    light: '#4BB4CC',
    dark: '#2D6C7A',
    darkBackground: '',
  },
]
