import useCourseTitles from 'components/utilities/hooks/useCourseTitles'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import React, { useContext } from 'react'
import deepCopy from 'utils/edit-map/deep-copy-map'
import CourseLabelTag from './course-label-tag/CourseLabelTag'
import c from './course-labels.module.scss'

const CourseLabels = () => {
  const { courseMap, setCourseMap, setInEditCourseMap, autosave } =
    useContext(Data)
  const { setEditMode } = useContext(Interface)
  const courseTitles = useCourseTitles(courseMap.courseLabels)

  const renderCourseLabels = () => {
    return courseMap.courseLabels?.map((item, i) => {
      const remove = () => {
        setCourseMap((f) => {
          const newMap = { ...f }

          if (newMap.courseLabels.length === 1) {
            delete newMap.courseLabels
          } else {
            newMap.courseLabels.splice(i, 1)
          }

          newMap.advancedStandings.forEach((u, i) => {
            if (u.courseLabel) {
              u.courseLabel === item && delete u.courseLabel
            }
          })
          Object.values(newMap.years).forEach((y) => {
            y.periods.forEach((tp) => {
              tp.blocks.forEach((u) => {
                if (u.courseLabel) {
                  u.courseLabel === item && delete u.courseLabel
                }
              })
            })
          })
          autosave(newMap)
          return newMap
        })
      }

      const bulkEdit = () => {
        const newMap = deepCopy(courseMap)
        setEditMode({ type: 'COURSE', code: item })
        setInEditCourseMap(newMap)
      }

      return (
        <CourseLabelTag
          code={item}
          title={courseTitles ? courseTitles[item] : null}
          key={i}
          remove={remove}
          bulkEdit={bulkEdit}
          colourIndex={courseMap.courseLabels.indexOf(item)}></CourseLabelTag>
      )
    })
  }

  return (
    <div className={c.courseLabels}>
      <div className={c.header}>
        <h3>Course Labels</h3>
      </div>

      <div className={c.listOfCourseLabels}>
        <ul className={c.list}>{renderCourseLabels()}</ul>
      </div>
    </div>
  )
}

export default CourseLabels
