import Accordion from 'components/browser/content/modules/accordion/Accordion'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import { format } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { PlannerSize } from '../Planner'
import { capitalise } from 'utils/transforms/string-transforms'
import { studyTypeText } from 'utils/data/studyType'
import c from './student-details.module.scss'

const StudentDetails = ({ top }) => {
  const { currentStudent } = useContext(CurrentStudent)
  const { plannerSize } = useContext(PlannerSize)
  const { courseMap } = useContext(Data)
  const [columns, setcolumns] = useState(2)

  useEffect(() => {
    setcolumns(plannerSize < 500 ? 1 : 2)
  }, [plannerSize])

  // expanded

  const [expanded, setExpanded] = useState(
    JSON.parse(localStorage.getItem('studentDetailsExpanded'))
  )

  useEffect(() => {
    localStorage.setItem('studentDetailsExpanded', JSON.stringify(expanded))
  }, [expanded])

  // render

  return courseMap ? (
    <div className={c.studentDetails} style={{ marginTop: `${top}px` }}>
      <Accordion
        expanded={expanded}
        onClick={(e) => setExpanded(!expanded)}
        header={
          <h3>
            {currentStudent.personName?.fullName} {'\u2013'}{' '}
            {currentStudent.identifiers?.callistaPersonID}
          </h3>
        }>
        <ul
          className={c.meta}
          style={{ grid: `auto-flow / repeat(${columns}, 1fr)` }}>
          {/* course */}
          <li>
            <label>{courseMap.transfer? 'Planned' : 'Active'} course</label>
            <div>
              {courseMap.code}
              {courseMap.version ? '.' + courseMap.version : null}{' '}
              {'\u2013'} {courseMap.title}
            </div>
          </li>

          {/* offering option */}
          <li>
            <label>Location - Study type</label>
            <div>
              {capitalise(courseMap.location?.id)} {'\u2013'}{' '}
              {studyTypeText(courseMap.location.attendanceType)}
            </div>
          </li>

          {/* enrolment status */}
          {!courseMap.transfer && (
            <li>
              <label>Enrolment status</label>
              <div>{capitalise(courseMap.status || '')}</div>
            </li>
          )}

          {/* start date */}
          {courseMap.transfer ? 
            <li>
              <label>Planned commencing semester</label>
              <div>
                {courseMap.startingSemester} {courseMap.displayYear || courseMap.startingYear}
              </div>
            </li>
          : 
            <li>
              <label>Commenced date</label>
              {currentStudent.courseEnrolments && (
                <div>
                  {format(new Date(courseMap.enrolmentDate), 'd MMM yyyy')}
                </div>
              )}
            </li>
          }
          

          {/* Citizenship status */}
          <li>
            <label>Citizenship status</label>
            <div>
              {currentStudent.studentIndicatorLamp.split(':')[1] ||
                currentStudent.citizenship?.citizenshipType.description}
            </div>
          </li>

          {/* WAM/GPA */}
          <li>
            <label>Current course WAM</label>
            <div>{courseMap.progression?.weightedAverageMark || 'NA'}</div>
          </li>
        </ul>
      </Accordion>
    </div>
  ) : null
}

export default StudentDetails
