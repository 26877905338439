import React, { useContext } from 'react'
import { courseLabelColors } from 'utils/colors/course-label-colors'
import { ContextMenuData } from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import c from './course-label-tag.module.scss'
import Icon from 'components/utilities/icons/Icon'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import { RouterState } from 'utils/Router'

const CourseLabelTag = ({
  code,
  title,
  colourIndex,
  bulkEdit,
  remove,
  noClick = false,
}) => {
  const { setMenu } = useContext(ContextMenuData)
  const { editMode } = useContext(Interface)
  const { redirect } = useContext(RouterState)

  const contextMenu = (e) => {
    e.preventDefault()
    setMenu({
      id: code,
      position: { x: e.clientX, y: e.clientY },
      items: [
        {
          name: `View ${code}`,
          icon: <Icon.Info />,
          fn: () => redirect(`browse/course/${code}`),
        },
        !editMode && {
          name: `Bulk edit ${code}`,
          icon: <Icon.Pencil />,
          fn: () => bulkEdit(code),
        },
        { name: 'BREAK' },
        !editMode && {
          name: `Remove ${code}`,
          icon: <Icon.Trash />,
          className: c.red,
          fn: () => remove(),
        },
      ],
    })
  }

  return (
    <li
      className={`${
        editMode?.type === 'COURSE' && editMode.code !== code && c.inActive
      } ${c.courseLabelTag}`}
      style={{ color: `${courseLabelColors[colourIndex]?.dark}` }}
      onContextMenu={noClick ? null : contextMenu}>
      {code}
      {title ? ` \u2013 ${title}` : null}
    </li>
  )
}

export default CourseLabelTag
