import { PanelSize } from 'components/main/Main'
import Icon from 'components/utilities/icons/Icon'
import {
  AutoSaveIndicator,
  Data,
} from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import React, { useContext, useEffect, useState } from 'react'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'

import c from './topbar.module.scss'
import Transition from 'components/utilities/transition/Transition'
import Toggle from 'components/utilities/toggle/Toggle'

import { UserData } from 'components/wrappers/user-data-wrapper/UserDataWrapper'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import { analytics } from 'utils/firebase'
import { getFacultyFromCourseCode } from 'utils/data/faculty'

const Topbar = () => {
  const { panelSize, setPanelSize } = useContext(PanelSize)
  const { setShowPlanningPanel } = useContext(Interface)

  const setPriority = (priority) => {
    switch (priority) {
      case 'planner':
        setPanelSize({ priority: 'planner', browser: 50, planner: 50 })
        break

      case 'browser':
        setPanelSize({ priority: 'browser', browser: 70, planner: 30 })
        break
      case 'even':
        setPanelSize({ priority: 'even', browser: 60, planner: 40 })
        break
      default:
        break
    }
  }

  // copy to clipboard

  const { selectedPlan } = useContext(Data)
  const { user } = useContext(UserData)
  const { currentStudent } = useContext(CurrentStudent)

  const copyToClipboard = async () => {
    try {
      // log event to analytics
      const userProperties = {
        id: user.id,
        name: user.displayName,
        type: 'Staff',
        faculty: getFacultyFromCourseCode(selectedPlan.code),
      }
      analytics.setUserProperties(userProperties)
      analytics.logEvent('planExported', {
        studentId: selectedPlan.student,
        studentName:
          currentStudent.personName.fullName,
        planId: selectedPlan.id,
        staffId: user.id,
        staffName: user.displayName,
        courseCode: selectedPlan.code,
        courseTitle: selectedPlan.title,
        faculty: getFacultyFromCourseCode(selectedPlan.code),
      })

      await navigator.clipboard
        .writeText(`${window.location.origin}/view/${selectedPlan.id}`)
        .then(() => {
          setCopied(true)
        })
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) setTimeout(() => setCopied(false), 2000)
  }, [copied])

  // display options

  const {
    showDisplayOptions,
    setShowDisplayOptions,
    displayOptions,
    setDisplayOptions,
  } = useContext(Interface)

  // lock options

  const [showLockOptions, setShowLockOptions] = useState(false)

  // render

  return (
    <nav className={c.topbar}>
      {/* left */}
      <div className={c.left}>
        <button
          onClick={(e) => setPriority('planner')}
          className={panelSize.priority === 'planner' ? c.active : null}
          title='Plan priority'>
          <Icon.ResizeLeft />
        </button>
        <button
          onClick={(e) => setPriority('even')}
          className={panelSize.priority === 'even' ? c.active : null}
          title='Even'>
          <Icon.ResizeEven />
        </button>
        <button
          onClick={(e) => setPriority('browser')}
          className={panelSize.priority === 'browser' ? c.active : null}
          title='Browser priority'>
          <Icon.ResizeRight />
        </button>
      </div>

      {/* right */}
      <div className={c.right}>
        <div className={c.autosave}>
          <AutoSaveIndicator />
        </div>

        {/* lock settings */}
        <span>
          <button
            onClick={() => {
              setShowLockOptions(!showLockOptions)
              setShowDisplayOptions(false)
            }}
            className={showLockOptions ? c.active : null}>
            <Icon.Lock />
          </button>

          <Transition
            show={showLockOptions}
            enterClass={c.enter}
            exitClass={c.exit}>
            <div className={c.displayOptions} id='display-options'>
              <h3>
                Lock settings
                <div
                  className={c.close}
                  onClick={() => {
                    setShowLockOptions(false)
                  }}>
                  <Icon.X />
                </div>
              </h3>

              <div className={c.optionsGroup}>
                <li>
                  <span>Lock enrolled units</span>
                  <Toggle
                    on={displayOptions.lockUnits}
                    onClick={() =>
                      setDisplayOptions((f) => {
                        return {
                          ...f,
                          lockUnits: !displayOptions.lockUnits,
                        }
                      })
                    }
                  />
                </li>
              </div>
            </div>
          </Transition>
        </span>

        {/* display settings */}
        <span>
          <button
            onClick={() => {
              setShowDisplayOptions(!showDisplayOptions)
              setShowLockOptions(false)
            }}
            className={showDisplayOptions ? c.active : null}>
            <Icon.Adjust />
          </button>

          <Transition
            show={showDisplayOptions}
            enterClass={c.enter}
            exitClass={c.exit}>
            <div className={c.displayOptions}>
              <h3>
                Display settings
                <div
                  className={c.close}
                  onClick={() => setShowDisplayOptions(false)}>
                  <Icon.X />
                </div>
              </h3>
              <ul className={c.optionsGroup}>
                <span className={c.header}>Display mode</span>
                <li>
                  <span>Condense</span>
                  <Toggle
                    on={displayOptions.condenseMode}
                    onClick={() =>
                      setDisplayOptions((f) => {
                        return {
                          ...f,
                          condenseMode: !displayOptions.condenseMode,
                        }
                      })
                    }
                  />
                </li>
              </ul>
              <ul className={c.optionsGroup}>
                <span className={c.header}>Plan display</span>
                <li>
                  <span>Withdrawn or failed units</span>
                  <Toggle
                    on={displayOptions.showFailedUnits}
                    onClick={() =>
                      setDisplayOptions((f) => {
                        return {
                          ...f,
                          showFailedUnits: !displayOptions.showFailedUnits,
                        }
                      })
                    }
                  />
                </li>
              </ul>
              <ul className={c.optionsGroup}>
                <span className={c.header}>
                  Unit display
                  <button
                    onClick={() =>
                      setDisplayOptions((f) => {
                        return {
                          ...f,
                          showDescription: false,
                          showNotes: false,
                          show6CPTags: false,
                        }
                      })
                    }>
                    Hide all
                  </button>
                </span>

                <li>
                  <span>Description</span>
                  <Toggle
                    on={displayOptions.showDescription}
                    onClick={() =>
                      setDisplayOptions((f) => {
                        return {
                          ...f,
                          showDescription: !displayOptions.showDescription,
                        }
                      })
                    }
                  />
                </li>

                <li>
                  <span>Notes</span>
                  <Toggle
                    on={displayOptions.showNotes}
                    onClick={() =>
                      setDisplayOptions((f) => {
                        return {
                          ...f,
                          showNotes: !displayOptions.showNotes,
                        }
                      })
                    }
                  />
                </li>
                <li>
                  <span>6CP tags</span>
                  <Toggle
                    on={displayOptions.show6CPTags}
                    onClick={() =>
                      setDisplayOptions((f) => {
                        return {
                          ...f,
                          show6CPTags: !displayOptions.show6CPTags,
                        }
                      })
                    }
                  />
                </li>
              </ul>
            </div>
          </Transition>
        </span>
        <button onClick={copyToClipboard} title='Export plan'>
          <Icon.Export />
        </button>
        {/* <button>
          <Icon.DotsVertical />
        </button> */}
        <button
          onClick={() => {
            setShowPlanningPanel(false)
          }}
          title='Close plan'>
          <Icon.X />
        </button>
      </div>

      {/* clipboard notification */}
      {copied && (
        <div className={c.copyNotification}>Link copied to clipboard</div>
      )}
    </nav>
  )
}

export default Topbar
