import { useCallback, useEffect, useState } from 'react'
import { callistaDetails } from 'utils/services'

const useCourseTitles = (courseCodes = []) => {
  const [courseTitles, setCourseTitles] = useState(null)

  const getCourses = useCallback(async () => {
    Promise.allSettled(
      courseCodes.map((courseCode) =>
        callistaDetails({ code: courseCode, type: 'course' })
      )
    ).then((courses) => {
      setCourseTitles(
        courses?.reduce((courseTitles, course) => {
          if (course.value) {
            courseTitles[course.value[Object.keys(course).length].code] =
              course.value[Object.keys(course).length].title
          }
          return courseTitles
        }, {})
      )
    })
  }, [courseCodes])

  useEffect(() => {
    if (courseCodes.length !== 0) getCourses()
  }, [getCourses, courseCodes])

  return courseTitles
}
export default useCourseTitles
