import LoadingIndicator from 'components/utilities/loading-indicator/LoadingIndicator'
import React, { useEffect, useState } from 'react'
import { defaultYear } from 'utils/data/years'
import { handbookSearch } from 'utils/services'
import c from './course-search.module.scss'

const CourseSearch = ({ selectCourse, value }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)

  useEffect(() => {
    setSearchTerm('')
  }, [])

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (searchTerm) {
        setLoading(true)
        handbookSearch({ target: searchTerm, type: 'course', /*implementationYear: ['2021', '2022'],*/ size: 10 }).then((res) => {
          setSearchResults(
            res.hits?.hits
            .filter(x => parseInt(x._source.implementationYear) >= defaultYear)
            // .filter(
            //   (a, i, arr) =>
            //     arr.findIndex((b) => a._source.code === b._source.code) === i
            // ) 
            || [null]
          )
          setLoading(false)
        })
      } else {
        setSearchResults([])
      }
    }, 300)

    return () => clearTimeout(debounce)
  }, [searchTerm])

  const groupCourses = (code) => {
    const years = {}
    searchResults
      .filter(c => c._source.code === code)
      .map(c => c._source)
      .forEach(c => years[c.implementationYear] = c)
    return years
  }

  // render

  return (
    <div className={c.courseSearch}>
      <div className={c.container}>
        <input
          autoFocus
          placeholder='Type to search'
          type='text'
          value={searchTerm || value}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setActive(true)}
        />

        <div className={c.searchResults}>
          {(active && (searchResults.length || loading)) && (
            <ul className={c.results}>
              {loading && (
                <li className={c.loading}>
                  <LoadingIndicator color='#204f88' />
                </li>
              )}
              {searchResults
              .filter(
                (a, i, arr) =>
                  arr.findIndex((b) => a._source.code === b._source.code) === i
              )
              .map((item, i) => {
                if (item) {
                  return (
                    <li
                      key={i}
                      onClick={() => {
                        setSearchTerm(item._source.code)
                        selectCourse(groupCourses(item._source.code))
                        setActive(false)
                      }}
                      >
                      <div className={c.code}>
                        <b>{item._source.code}</b>
                        {/* <span>{item._source.implementationYear}</span> */}
                      </div>
                      <div className={c.name}>{item._source.title}</div>
                    </li>
                  )
                } else {
                  return <li key={i}>No results</li>
                }
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default CourseSearch
