import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import c from './progression-map.module.scss'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import checkMap from '../../check-map'
import PMapTable from './PMapTable'
import { nanoid } from 'nanoid'
import Icon from 'components/utilities/icons/Icon'
import { gradeStatus } from 'utils/data/grades'
import { fs } from 'utils/firebase'
import { capitalise } from 'utils/transforms/string-transforms'
import LoadingScreen from 'components/utilities/loading-indicator/LoadingScreen'
import { getProgressionMaps } from 'utils/services'
import { years, defaultYear } from 'utils/data/years'
import { BrowserData } from 'components/browser/Browser'
import { ContextMenuData } from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'

// Data transform to block format
const blockify = (code, description, label, creditPoints, y, tp, i) => {
  const newBlock = {
    id: nanoid(),
    type: 'BLOCK',
    blockType: 'UNIT',
    origin: 'PMAP',
    name: code || null,
    elective: !code,
    electiveId: null,
    description,
    courseLabel: label,
    year: y,
    periodIndex: tp,
    index: i,
    creditPoints: creditPoints,
  }

  return newBlock
}

//
// start
//

const ProgressionMap = ({ pageData, currentCourse }) => {
  const {
    courseMap,
    setCourseMap,
    autosave,
    flatCourseMap,
    selectedPMap,
    setSelectedPMap,
    selectedPMapCourseLabels,
  } = useContext(Data)
  const { historyValues, updateHistory } = useContext(BrowserData)
  const { setMenu } = useContext(ContextMenuData)
  const { editMode } = useContext(Interface)

  const filteredFlatMap = flatCourseMap.filter((item) => {
    const grade = gradeStatus(item.grade)
    if (['DISCONTIN'].includes(item.status)) return false
    if (['FAIL', 'WITHDRAWN'].includes(grade)) return false
    return true
  })

  //Get all course progression maps base on the year and course
  const [studentEnrolledYear] = useState(
    courseMap ? courseMap.startingYear : null
  )

  const [year, setYear] = useState(
    historyValues?.pMapYear
      ? historyValues.pMapYear
      : studentEnrolledYear
      ? parseInt(studentEnrolledYear) >= 2016
        ? studentEnrolledYear
        : defaultYear
      : defaultYear
  )

  const changeYear = (y) => {
    setYear(y)
    updateHistory((page) => {
      page['pMapYear'] = y
      return page
    })
  }

  const [course] = useState(
    //courses.find((item) => item === selectedPlan.code)
    pageData?.code
  )

  const [maps, setMaps] = useState([])
  const [urls, setUrls] = useState(null)
  const [loading, setLoading] = useState(true)
  const [blockBulkAdd, setBlockBulkAdd] = useState(false)
  const [courseLabelApplied, setCourseLabelApplied] = useState(false)
  const [bulkCount, setBulkCount] = useState({ unit: 0, elective: 0 })
  const getPDFLinkTimestamp = useRef()

  const required = useMemo(() => ['code', 'year'], [])

  const addCache = (code, year, maps) => {
    let cache = JSON.parse(sessionStorage.getItem('mapCache')) || {}
    cache[`${code}-${year}`] = maps
    sessionStorage.setItem('mapCache', JSON.stringify(cache))
  }

  const [filters, setFilters] = useState(null)

  const changeFilters = (f) => {
    setFilters(f)
    updateHistory((page) => {
      page['pMapFilters'] = {
        filters: f,
        year: year,
      }
      return page
    })
  }

  const getFilters = useCallback(
    (maps) => {
      let filterList = {} //{key: [values]}
      maps.forEach((doc) => {
        doc.filter_fields.forEach((f) => {
          //add values
          if (!required.includes(f)) {
            if (filterList[f]) {
              if (!filterList[f].values.includes(doc[f])) {
                filterList[f].values.push(doc[f])
              }
            } else {
              filterList[f] = {
                key: f,
                values: [doc[f]],
                //selected: doc[f],
              }
            }
          }
        })
        //sort values and select
        Object.keys(filterList).forEach(f => {
          let sorted = filterList[f].values
            .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
          filterList[f].values = sorted
          filterList[f]['selected'] = sorted[0]
        })
      })
      setFilters(filterList)
    },
    [required]
  )

  //filter maps onyear and course code
  useEffect(() => {
    if (year && course) {
      setLoading(true)

      //check cache
      let cache = JSON.parse(sessionStorage.getItem('mapCache'))
      if (cache?.[`${course}-${year}`]) {
        setMaps(cache[`${course}-${year}`])
        getFilters(cache[`${course}-${year}`])
        setLoading(false)
      } else {
        //no in cache get fresh maps
        getProgressionMaps({
          filters: [
            { key: 'code', value: course },
            { key: 'year', value: year },
          ],
        }).then((docs) => {
          //add to cache
          addCache(course, year, docs)

          setMaps(docs)
          getFilters(docs)
          setLoading(false)
        })
      }
      if (historyValues?.pMapFilters?.year === year) {
        setFilters(historyValues.pMapFilters.filters)
      }
    }
  }, [year, course, getFilters, historyValues?.pMapFilters])

  const getMap = useCallback(() => {
    if (filters) {
      let filtered = maps.filter((map) => {
        return !map.filter_fields
          .filter((f) => !required.includes(f))
          .some((f) => {
            return filters[f]?.selected !== map[f]
          })
      })
      let chosenOne = filtered[0]
      if (chosenOne) {
        chosenOne.map?.forEach((year, y) => {
          year.periods.forEach((period, tp) => {
            period.units.forEach((unit, u) => {
              if (chosenOne.map[y].periods[tp].units[u].type !== 'BLOCK') {
                chosenOne.map[y].periods[tp].units[u] = blockify(
                  unit.unit_code || unit.name,
                  unit.description,
                  unit.label || null,
                  unit.creditPoints || null,
                  y,
                  tp,
                  u
                )
              }
            })
          })
        })
        return chosenOne
      } else {
        return {}
      }
    } else {
      return {}
    }
  }, [filters, maps, required])

  useEffect(() => {
    // create timestamp for urls race conditions
    const timestamp = new Date().getTime()
    getPDFLinkTimestamp.current = timestamp
    const selected = getMap()
    //check for stored maps
    let existing = courseMap ? courseMap.pMaps?.[course]?.[year] : null
    //if existing maps, check if they match currently selected filters
    existing = Array.isArray(existing)
      ? existing.filter((p) => {
          //isEqual(p.filters, selected.filters)
          if (!p.filter_fields) {
            return []
          }
          return (
            filters &&
            !Object.keys(filters).some((f) => p[f] !== filters[f].selected)
          )
        })
      : []
    //either use existing, matching map, or set new one
    let displayedMap = existing?.length > 0 ? existing[0] : selected
    setSelectedPMap(displayedMap)
    //get urls to use
    if (displayedMap.url) {
      setUrls(
        Array.isArray(displayedMap.url) ? displayedMap.url : [displayedMap.url]
      )
      //} else if (!!Object.keys(displayedMap).length){
    } else {
      fs.collection('map-links')
        .doc(`${year}-${course}`)
        .get()
        .then((doc) => {
          //if timestamps don't match, do not set urls
          if (timestamp !== getPDFLinkTimestamp.current) {
            return
          }
          if (doc.exists) {
            setUrls([doc.data().url])
          } else {
            setUrls(null)
          }
        })
        .catch(() => {
          setUrls(null)
        })
    }
  }, [filters, courseMap, course, year, setSelectedPMap, getMap])

  const getTheRemainingUnits = () => {
    const onPMapUnitsList = []
    const onPMapUnits = []
    const flatPMap = []

    selectedPMap.map.forEach((y) => {
      y.periods.forEach((tp) => {
        tp.units.forEach((u) => {
          flatPMap.push(u)
        })
      })
    })

    flatPMap.forEach((u) => {
      let onMap = u.name ? checkMap(u.name, 'name', filteredFlatMap) : null
      if (onMap) {
        onPMapUnitsList.push(u)
      }
    })

    onPMapUnitsList.forEach((u) => {
      onPMapUnits.push(u.name)
    })

    let remainingList = []

    filteredFlatMap.forEach((f) => {
      let x = { ...f }
      if (x.creditType === 'unspecifiedCredit') {
        let name = `Level ${x.unitLevel}${
          x.disciplineDescription
            ? ` ${capitalise(x.disciplineDescription)}`
            : ''
        }`
        let sameName = remainingList.findIndex((a) => a.name === name)
        x.name = name

        let selected = flatPMap.filter((p) => p.electiveId === x.id).length

        if (sameName > 0) {
          //if thing with same exists, combine cp and replace
          x.creditPoints += remainingList[sameName].creditPoints
          x.allocated = selected * 6 >= x.creditPoints
          x.displayName =
            name + ` (${x.creditPoints - selected * 6}CP remaining) \u2013 6CP`
          remainingList[sameName] = x
        } else {
          x.allocated = selected * 6 >= x.creditPoints
          x.displayName =
            name + ` (${x.creditPoints - selected * 6}CP remaining) \u2013 6CP`
          remainingList.push(x)
        }
      } else {
        if (!onPMapUnits.includes(x.name)) {
          remainingList.push(x)
        }
      }
    })

    return remainingList
  }

  const bulkAdd = () => {
    const bulkList = []
    const counts = { unit: 0, elective: 0 }

    selectedPMap.map.forEach((y) => {
      y.periods.forEach((tp) => {
        tp.units.forEach((u) => {
          const onMap = u.name
            ? checkMap(u.name, 'name', filteredFlatMap)
            : null
          if (!onMap) {
            let x
            if (u.elective && !u.selectedElective) {
              x = {
                ...u,
                origin: 'MAP',
                blockType: 'COMMENT',
                id: nanoid(),
                status: 'DISPLAY',
              }
              counts.elective += 1
            } else if (u.name) {
              x = {
                ...u,
                origin: 'MAP',
                blockType: 'UNIT',
                id: nanoid(),
                status: 'SUGGESTED',
              }
              counts.unit += 1
            }
            if (x) bulkList.push(x)
          }
        })
      })
    })
    setBulkCount(counts)
    setCourseMap((f) => {
      let copy = { ...f }
      // if (!copy.years["Progression Map"]) {
      //   copy.years["Progression Map"] = {
      //     id: nanoid(),
      //     name: "Progression Map",
      //     periods: []
      //   }
      // }
      let keys = Object.keys(copy.years)
      copy.years[keys[keys.length - 1]].periods.push({
        id: nanoid(),
        name: 'Remaining units from map',
        blocks: [...bulkList],
      })
      autosave(copy)
      return copy
    })
    setBlockBulkAdd(true)
    setTimeout(() => {
      setBlockBulkAdd(false)
    }, 5000)
  }

  const remaining = selectedPMap.map ? getTheRemainingUnits() : []

  //bulk add course labels
  const bulkApplyCourseLabels = () => {
    setCourseMap((f) => {
      let newMap = { ...f }
      const indexedCourseMap = {}
      const indexedPMap = {}

      //add course labels from pmap to new course map
      newMap.courseLabels = []
      selectedPMapCourseLabels.forEach((item) => newMap.courseLabels.push(item))

      // generate indexedCourseMap
      Object.values(newMap.years).forEach((y) => {
        y.periods.forEach((tp) => {
          tp.blocks.forEach((u) => {
            if (!u.name) return null
            //if failed / withdrawn there can be more units
            if (indexedCourseMap[u.name]) {
              indexedCourseMap[u.name].push(u)
            } else {
              indexedCourseMap[u.name] = [u]
            }
          })
        })
      })
      Object.values(newMap.advancedStandings).forEach((c) => {
        if (!c.name) return null
        if (indexedCourseMap[c.name]) {
          indexedCourseMap[c.name].push(c)
        } else {
          indexedCourseMap[c.name] = [c]
        }
      })

      // generate indexedPMap
      selectedPMap.map.forEach((y) => {
        y.periods.forEach((tp) => {
          tp.units.forEach((u) => {
            if (!u.name && !u.selectedElective) return null
            //if failed / withdrawn
            if (u.name) {
              if (indexedPMap[u.name]) {
                indexedPMap.name.push(u)
              } else {
                indexedPMap[u.name] = [u]
              }
            } else {
              if (indexedPMap[u.selectedElective]) {
                indexedPMap[u.selectedElective].push(u)
              } else {
                indexedPMap[u.selectedElective] = [u]
              }
            }
          })
        })
      })

      selectedPMap.map.forEach((y) => {
        y.periods.forEach((tp) => {
          tp.units.forEach((u) => {
            if (!u.name && !u.selectedElective) return null
            //unit
            if (u.name) {
              if (indexedCourseMap.hasOwnProperty(u.name)) {
                indexedCourseMap[u.name].forEach((unit) => {
                  unit.courseLabel = u.courseLabel
                })
              }
            }
            //selected elective
            else {
              if (indexedCourseMap.hasOwnProperty(u.selectedElective)) {
                indexedCourseMap[u.selectedElective].forEach((unit) => {
                  unit.courseLabel = u.courseLabel
                })
              }
            }
          })
        })
      })

      //sync and remove course labels from planning panel
      Object.keys(indexedCourseMap).forEach((name) => {
        if (!indexedPMap.hasOwnProperty(name)) {
          indexedCourseMap[name].forEach(
            (u) => u.courseLabel && delete u.courseLabel
          )
        }
      })

      autosave(newMap)
      return newMap
    })
    setCourseLabelApplied(true)
    setTimeout(() => {
      setCourseLabelApplied(false)
    }, 5000)
  }

  // context menu

  const contextMenu = (e) => {
    e.preventDefault()
    setMenu({
      position: { x: e.clientX, y: e.clientY },
      items: [
        !editMode && {
          name: `Bulk add remaining units`,
          icon: <Icon.Add />,
          fn: () => bulkAdd(),
        },
        !editMode &&
          selectedPMap.doubleDegree && {
            name: `Apply course labels`,
            icon: <Icon.Wand />,
            fn: () => bulkApplyCourseLabels(),
          },
      ],
    })
  }

  // render
  return (
    <LoadingScreen loaded={!loading}>
      <div>
        {/* filters */}
        <div className={c.mapFilters}>
          <label>
            <span>Year:</span>
            <fieldset>
              <select
                value={year}
                onChange={(e) => {
                  changeYear(e.target.value)
                }}>
                {years.map((y) => (
                  <option key={`key-year-${y}`} value={y}>
                    {y}
                  </option>
                ))}
              </select>
              <div className={c.dropdown}>
                {year} <Icon.ChevronDown />
              </div>
            </fieldset>
          </label>
          {filters &&
            Object.keys(filters).map((f) => {
              let filterData = filters[f]
              return (
                <label key={filterData.key}>
                  <span>{capitalise(filterData.key)}:</span>
                  <fieldset>
                    <select
                      value={filterData.selected || filterData.values[0]}
                      onChange={(e) => {
                        let copy = { ...filters }
                        copy[f].selected = e.target.value
                        changeFilters(copy)
                      }}>
                      {filterData.values
                        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
                        .map((v) => {
                          return (
                            <option key={`${f}-${v}`} value={v}>
                              {capitalise(v)}
                            </option>
                          )
                        })}
                    </select>
                    <div className={c.dropdown}>
                      {capitalise(filterData.selected || filterData.values[0])}{' '}
                      <Icon.ChevronDown />
                    </div>
                  </fieldset>
                </label>
              )
            })}
        </div>
        <div className={c.progressionMapSection}>
          <section className={c.progressionMapTable}>
            <div className={c.progressionMapHeader}>
              {!!urls?.[0] && (
                <div className={c.progressionMapTitle}>
                  <span>
                    Course maps of {course} for students commencing in {year}:
                  </span>
                  <div>
                    {urls.map((url, i) => {
                      let chopped = url.split('/')
                      let label = chopped[chopped.length - 1]
                      return (
                        <a href={url} target='_blank' rel='noreferrer' key={i}>
                          <span>{label}</span>
                          <Icon.External />
                        </a>
                      )
                    })}
                  </div>
                </div>
              )}
              {selectedPMap.map &&
                !!Object.keys(courseMap?.years || {}).length && (
                  <span onClick={contextMenu} className={c.menuIcon}>
                    <Icon.DotsVertical />
                  </span>
                )}
            </div>
            {selectedPMap.map ? (
              <div>
                <PMapTable data={selectedPMap} remaining={remaining} />
              </div>
            ) : (
              <span>No course progression map available</span>
            )}
          </section>

          {selectedPMap.map && !!Object.keys(courseMap?.years || {}).length && (
            <div className={c.progressionMapSection}>
              {/* bulk add remaining units */}
              <section className={c.bulkAdd}>
                <div className={c.add}>
                  <h2>Add remaining units to student's plan in bulk</h2>
                  <button
                    className={c.secondaryButton}
                    onClick={bulkAdd}
                    disabled={blockBulkAdd || editMode}>
                    <Icon.Plus />
                    {blockBulkAdd ? 'Added' : 'Add'}
                  </button>
                  {blockBulkAdd && (
                    <div className={c.confirm}>
                      <Icon.CircleCheck /> Added {bulkCount.unit} unit
                      {bulkCount.unit !== 1 && 's'} and {bulkCount.elective}{' '}
                      comment
                      {bulkCount.elective !== 1 && 's'}
                    </div>
                  )}
                </div>
                {/* bulk apply double degree course labels */}
                {selectedPMap.doubleDegree && (
                  <div className={c.add}>
                    <h2>Apply course labels to student’s plan</h2>
                    <button
                      className={c.secondaryButton}
                      disabled={courseLabelApplied || editMode}
                      onClick={bulkApplyCourseLabels}>
                      <Icon.Wand />
                      Apply
                    </button>
                    {courseLabelApplied && (
                      <div className={c.confirm}>
                        <Icon.CircleCheck /> Successfully applied!
                      </div>
                    )}
                  </div>
                )}
              </section>
            </div>
          )}

          {selectedPMap.notes && (
            <div className={c.progressionMapSection}>
              <section className={c.progressionMapTable}>
                <span>Notes:</span>
                <p className={c.notes}>{selectedPMap.notes}</p>
              </section>
            </div>
          )}
        </div>
      </div>
    </LoadingScreen>
  )
}

export default ProgressionMap
