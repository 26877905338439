import { useDndMonitor } from '@dnd-kit/core'
import indexID from 'components/planner/mapper/index-id'
import { nanoid } from 'nanoid'
import React, { useContext } from 'react'
import deepCopy from 'utils/edit-map/deep-copy-map'
import { Data } from '../course-map-data-wrapper/CourseMapDataWrapper'
import { Interface } from '../interface-data-wrapper/InterfaceDataWrapper'
import { DragDropData } from './DragDropWrapper'

const Monitor = ({ children }) => {
  const { setEditMode, setOffsetPlanner } = useContext(Interface)
  const { courseMap, setCourseMap, setInEditCourseMap, autosave } =
    useContext(Data)
  const { setSelected } = useContext(DragDropData)

  useDndMonitor({
    // start
    //
    onDragStart(e) {
      const o = e.active.data.current
      setSelected(o)

      if (o.type === 'BLOCK' || o.type === 'AOS') {
        if (o.origin === 'BROWSER' || o.origin === 'PMAP') {
          setOffsetPlanner(e.active.rect.current.initial.offsetTop)
        }
      }
    },

    // move
    //
    onDragMove(e) {},

    // over
    //
    onDragOver(e) {
      const o = e.active.data.current
      const d = e?.over?.data?.current

      // console.log(e)

      // console.log(
      //   `${o.origin} \u2013 ${o.description} -> ${d.origin} ${d.description}`
      // )

      // shift periods

      if (o?.type === 'PERIOD' && d?.type === 'PERIOD') {
        setCourseMap((f) => {
          const newMap = { ...f }

          newMap.years[d.year].periods.splice(
            d.periodIndex,
            0,
            newMap.years[o.year].periods.splice(o.periodIndex, 1)[0]
          )

          return newMap
        })
      }

      // shift blocks

      if (o?.type === 'BLOCK' && d?.type === 'BLOCK') {
        // from period to another period
        if (o.origin === 'MAP' && d.origin === 'MAP') {
          if (o.year !== d.year || o.periodIndex !== d.periodIndex) {
            setCourseMap((f) => {
              const newMap = { ...f }

              const oIndex = newMap.years[o.year].periods[
                o.periodIndex
              ].blocks.findIndex(
                (item) => item.id === o.id && item.status === o.status
              )
              const dIndex = newMap.years[d.year].periods[
                d.periodIndex
              ].blocks.findIndex(
                (item) => item.id === d.id && item.status === d.status
              )

              if (oIndex > -1 && dIndex > -1) {
                newMap.years[d.year].periods[d.periodIndex].blocks.splice(
                  dIndex,
                  0,
                  newMap.years[o.year].periods[o.periodIndex].blocks.splice(
                    oIndex,
                    1
                  )[0]
                )
              }
              return newMap
            })
          }
        }

        // move blocks to map from !map

        if (
          o.origin === 'BROWSER' ||
          o.origin === 'PMAP' ||
          o.origin === 'CREDIT'
        ) {
          if (d?.origin === 'MAP') {
            // unit
            if (o.blockType === 'UNIT' && !o.elective) {
              if (
                !courseMap.years[d.year].periods[d.periodIndex].blocks.some(
                  (item) => item.name === o.name
                )
              ) {
                setCourseMap((f) => {
                  const newMap = { ...f }

                  const newItem = {
                    ...o,
                    origin: 'MAP',
                    clone: true,
                    status: 'SUGGESTED',
                    index: d.index || 0,
                    year: d.year,
                    periodIndex: d.periodIndex,
                    sortable: null,
                  }
                  if (newItem.grade) newItem.grade = null
                  if (newItem.mark) newItem.mark = null

                  //if (!indexID(newMap, newItem, 'name').fullYear && newItem) {
                  let blocksRef =
                    newMap.years[d.year].periods[d.periodIndex].blocks

                  blocksRef.splice(d.index, 0, newItem)
                  blocksRef = blocksRef.filter((item) => item)
                  newMap.years[d.year].periods[d.periodIndex].blocks = blocksRef
                  //}
                  return newMap
                })
              }
            }

            // elective
            if (o.elective) {
              setCourseMap((f) => {
                const newMap = { ...f }

                if (
                  !newMap.years[d.year].periods[d.periodIndex].blocks.some(
                    (item) => item.id === o.id
                  )
                ) {
                  const newItem = {
                    ...o,
                    clone: true,
                    elective: null,
                    name: null,
                    type: 'BLOCK',
                    blockType: 'COMMENT',
                    origin: 'MAP',
                    index: d.index || 0,
                    year: d.year,
                    periodIndex: d.periodIndex,
                  }

                  if (!indexID(newMap, newItem).fullYear && newItem) {
                    let blocksRef =
                      newMap.years[d.year].periods[d.periodIndex].blocks

                    blocksRef.splice(d.index, 0, newItem)
                    blocksRef = blocksRef.filter((item) => item)
                  }
                }

                return newMap
              })
            }
          }
        }
      }

      // empty periods

      if (d?.type === 'PERIOD' && d?.blocks?.length === 0) {
        if (
          o.blockType === 'UNIT' ||
          o.blockType === 'CREDIT' ||
          o.blockType === 'COMMENT'
        ) {
          if (o.origin === 'MAP') {
            setCourseMap((f) => {
              const newMap = { ...f }

              newMap.years[d.year].periods[d.periodIndex].blocks.splice(
                d.index,
                0,
                newMap.years[o.year].periods[o.periodIndex].blocks.splice(
                  o.index,
                  1
                )[0]
              )

              return newMap
            })
          }

          if (o.origin === 'BROWSER' || o.origin === 'PMAP') {
            setCourseMap((f) => {
              const newMap = { ...f }

              const newItem = {
                ...o,
                clone: true,
                origin: 'MAP',
                status: 'SUGGESTED',
                index: d.index || 0,
                year: d.year,
                periodIndex: d.periodIndex,
              }
              if (newItem.grade) newItem.grade = null
              if (newItem.mark) newItem.mark = null

              if (o.elective) {
                newItem.elective = null
                newItem.name = null
                newItem.blockType = 'COMMENT'
                newItem.status = null
              }

              if (!indexID(newMap, newItem).fullYear && newItem) {
                newMap.years[d.year].periods[d.periodIndex].blocks = [newItem]
              }

              return newMap
            })
          }
        }
      }
    },

    // end
    //
    onDragEnd(e) {
      setOffsetPlanner(0)
      setSelected(null)
      const o = e.active.data.current
      const d = e?.over?.data?.current

      if (o?.type === 'PERIOD' && d?.type === 'PERIOD') {
        setCourseMap((f) => {
          const newMap = { ...f }

          newMap.years[d.year].periods.splice(
            d.periodIndex,
            0,
            newMap.years[o.year].periods.splice(o.periodIndex, 1)[0]
          )

          autosave(newMap)
          return newMap
        })
      }

      if (o?.type === 'BLOCK' && d?.type === 'BLOCK') {
        if (
          o.origin === 'MAP' &&
          d.origin === 'MAP' &&
          o.year === d.year &&
          o.periodIndex === d.periodIndex
        ) {
          setCourseMap((f) => {
            const newMap = { ...f }

            const oRef = newMap.years[o.year].periods[o.periodIndex].blocks
            const dRef = newMap.years[d.year].periods[d.periodIndex].blocks

            if (oRef[o.index].clone) oRef[o.index].id = nanoid()

            oRef.splice(d.index, 0, dRef.splice(o.index, 1)[0])

            autosave(newMap)
            return newMap
          })
        }
      }

      if (o?.type === 'AOS') {
        if (d?.origin === 'MAP' || d?.origin === 'CREDIT') {
          if (!courseMap.aos?.some((item) => item === o.name)) {
            setInEditCourseMap((f) => {
              const newMap = deepCopy(courseMap)

              let type = o.blockType.slice(0, 2).toUpperCase()
              if (type === 'UG' || type === 'PG') {
                type = 'SP'
              }

              const newAoS = {
                code: o.name,
                name: `${type} \u2013 ${o.name} ${o.description}`,
              }

              newMap.aos.push(newAoS)

              setEditMode({ type: 'AOS', code: o.name })

              o.eligible?.forEach((item) => {
                // if unit is in map
                const inMap = indexID(courseMap, {
                  type: 'AOS',
                  name: item,
                })

                if (inMap) {
                  newMap.years[inMap.year].periods[inMap.period].blocks[
                    inMap.block
                  ].aos
                    ? newMap.years[inMap.year].periods[inMap.period].blocks[
                        inMap.block
                      ].aos.push(newAoS)
                    : (newMap.years[inMap.year].periods[inMap.period].blocks[
                        inMap.block
                      ].aos = [newAoS])
                }

                // if unit is in advanced standings
                const indexOfAS = newMap.advancedStandings?.findIndex(
                  (as) => as.name === item
                )

                if (indexOfAS > -1) {
                  newMap.advancedStandings[indexOfAS].aos
                    ? newMap.advancedStandings[indexOfAS].aos.push(o.name)
                    : (newMap.advancedStandings[indexOfAS].aos = [o.name])
                }
              })
              return newMap
            })
          }
        }
      }
    },
  })

  return <>{children}</>
}

export default Monitor
