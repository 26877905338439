import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Drag } from 'components/utilities/drag-drop/DragDrop'
import c from './blocks.module.scss'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { ContextMenuData } from 'components/wrappers/context-menu-wrapper/ContextMenuWrapper'
import Icon from 'components/utilities/icons/Icon'
import { DragDropData } from 'components/wrappers/drag-drop-wrapper/DragDropWrapper'
import indexID from '../index-id'
import { RouterState } from 'utils/Router'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import { capitalise } from 'utils/transforms/string-transforms'
import Toggle from 'components/utilities/toggle/Toggle'
import { gradeStatus, isPass } from 'utils/data/grades'
import TextArea from 'components/utilities/text-area/TextArea'
import { ContentContext } from 'components/browser/content/Content'
import Note from './note/Note'
import NoteTag from './note-tag/NoteTag'
import CreditPointsTag from './credit-points-tag/CreditPointsTag'
import CourseLabel from './course-label/CourseLabel'
import AosTag from 'components/planner/student-details/area-of-study/AosTag/AosTag'
import deepCopy from 'utils/edit-map/deep-copy-map'
import { callistaDetails } from 'utils/services'

const Block = ({
  block,
  disabled,
  style,
  electives = [],
  fake = false,
  year = null,
  periodIndex = null,
  index,
  filler,
}) => {
  const { selected } = useContext(DragDropData)
  const {
    courseMap,
    currentCourseMap,
    setCourseMap,
    setInEditCourseMap,
    autosave,
    selectedPMap,
  } = useContext(Data)
  const { setMenu } = useContext(ContextMenuData)
  const { redirect } = useContext(RouterState)
  const {
    editMode,
    displayOptions: { condenseMode },
  } = useContext(Interface)
  const selectedYear = useContext(ContentContext)?.selectedYear

  // display options
  const { displayOptions } = useContext(Interface)
  const [locked, setLocked] = useState(false)
  const [hide, setHide] = useState(false)

  useEffect(() => {
    if (displayOptions.lockUnits) {
      if (['ENROLLED'].includes(block.status)) {
        setLocked(true)
      } else {
        setLocked(false)
      }
    } else {
      setLocked(false)
    }
  }, [block.status, displayOptions])

  useEffect(() => {
    if (
      !displayOptions.showFailedUnits &&
      ['MAP', 'CREDIT'].includes(block.origin)
    ) {
      const grade = gradeStatus(block.grade)
      if (['DISCONTIN'].includes(block.status)) setHide(true)
      if (block.grade && ['FAIL', 'WITHDRAWN'].includes(grade)) setHide(true)
    } else {
      setHide(false)
    }
  }, [block, displayOptions])

  // remove block

  const removeBlock = () => {
    setCourseMap((f) => {
      const newMap = { ...f }
      const obj = indexID(courseMap, block)

      newMap.years[obj.year].periods[obj.period].blocks.splice(obj.block, 1)

      autosave(newMap)
      return newMap
    })
  }

  const [checkUpdate, setCheckUpdate] = useState(!!block.clone)
  useEffect(() => {
    if (checkUpdate && block.blockType === 'UNIT') {
      setCheckUpdate(false)
      callistaDetails({ code: block.name, type: 'unit' })
        .then(r => {
          const latest = Object.values(r)
            .sort((a, b) => b.version - a.version)[0]
          if (block.description !== latest.title || block.creditPoints !== latest.creditPoints) {
            setCourseMap((f) => {
              const newMap = { ...f }
              const obj = indexID(courseMap, block)
              
              const updated = { 
                ...block,
                description: latest.title,
                creditPoints: latest.creditPoints,
                clone: false
              }
        
              newMap.years[obj.year].periods[obj.period].blocks[obj.block] = updated
              
              //console.log('unit has been updated')
              autosave(newMap)
              return newMap
            })
          }
        })
      
    }
  }, [autosave, block, checkUpdate, courseMap, setCourseMap])

  // edit comment

  const [commentEditMode, setCommentEditMode] = useState(
    block.status === 'EDIT'
  )
  const [comment, setComment] = useState(block.description)
  const commentRef = useRef(null)

  useEffect(() => {
    if (commentEditMode) {
      setComment(block.description)
      commentRef?.current?.select()
    }
  }, [block.description, commentEditMode])

  const editComment = () => {
    setCommentEditMode(true)
  }

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      saveEdit()
    }

    if (e.key === 'Escape') {
      setComment(block.description)
      setCommentEditMode(false)
    }
  }

  const saveEdit = () => {
    setCommentEditMode(false)
    setCourseMap((f) => {
      const newMap = { ...f }
      const blockRef = newMap.years[year].periods[periodIndex].blocks[index]
      blockRef.description = comment
      blockRef.status = 'DISPLAY'
      autosave(newMap)
      return newMap
    })
  }

  // add aos
  const addAoS = (item) => {
    if (editMode?.type === 'AOS') {
      setInEditCourseMap((f) => {
        const newMap = deepCopy(f)
        if (block.origin === 'MAP') {
          const blockRef = newMap.years[year].periods[periodIndex].blocks[index]

          if (!blockRef.aos) {
            blockRef.aos = [item]
          } else if (!blockRef.aos.includes(item)) {
            blockRef.aos.push(item)
          }
        }

        if (block.origin === 'CREDIT') {
          const blockRef = newMap.advancedStandings[index]
          if (!blockRef.aos) {
            blockRef.aos = [item]
          } else if (!blockRef.aos.includes(item)) {
            blockRef.aos.push(item)
          }
        }
        return newMap
      })
    } else {
      setCourseMap((f) => {
        const newMap = { ...f }

        if (block.origin === 'MAP') {
          const blockRef = newMap.years[year].periods[periodIndex].blocks[index]
          blockRef.aos ? blockRef.aos.push(item) : (blockRef.aos = [item])
        }

        if (block.origin === 'CREDIT') {
          const blockRef = newMap.advancedStandings[index]
          blockRef.aos ? blockRef.aos.push(item) : (blockRef.aos = [item])
        }

        autosave(newMap)
        return newMap
      })
    }
  }

  // remove aos

  const removeAoS = (item) => {
    if (editMode?.type === 'AOS') {
      setInEditCourseMap((f) => {
        const newMap = deepCopy(f)

        if (block.origin === 'MAP') {
          const blockRef = newMap.years[year].periods[periodIndex].blocks[index]
          blockRef.aos = blockRef.aos.filter((aos) => aos.code !== item.code)
        }

        if (block.origin === 'CREDIT') {
          const blockRef = newMap.advancedStandings[index]
          blockRef.aos = blockRef.aos.filter((aos) => aos.code !== item.code)
        }
        return newMap
      })
    } else {
      setCourseMap((f) => {
        const newMap = { ...f }

        if (block.origin === 'MAP') {
          const blockRef = newMap.years[year].periods[periodIndex].blocks[index]
          blockRef.aos = blockRef.aos.filter((aos) => aos.code !== item.code)
        }

        if (block.origin === 'CREDIT') {
          const blockRef = newMap.advancedStandings[index]
          blockRef.aos = blockRef.aos.filter((aos) => aos.code !== item.code)
        }

        autosave(newMap)
        return newMap
      })
    }
  }

  // toggle aos

  const toggleAoS = () => {
    block.aos?.some((item) => item.code === editMode.code)
      ? removeAoS({ code: editMode.code })
      : addAoS({ code: editMode.code })
  }

  // change size

  const changeSize = (size) => {
    setCourseMap((f) => {
      const newMap = { ...f }
      newMap.years[year].periods[periodIndex].blocks[index].size = size
      autosave(newMap)
      return newMap
    })
  }

  // notes

  const [editNote, setEditNote] = useState(false)

  const addNote = () => {
    setCourseMap((f) => {
      const newMap = { ...f }
      if (block.origin === 'CREDIT') {
        newMap.advancedStandings[index].note = 'New note'
      } else {
        newMap.years[year].periods[periodIndex].blocks[index].note = 'New note'
      }
      autosave(newMap)
      setEditNote(true)
      return newMap
    })
  }

  const removeNote = () => {
    setCourseMap((f) => {
      const newMap = { ...f }
      if (block.origin === 'CREDIT') {
        newMap.advancedStandings[index].note = null
      } else {
        newMap.years[year].periods[periodIndex].blocks[index].note = null
      }
      autosave(newMap)
      return newMap
    })
  }

  // transfer eligibility
  const toggleEligible = () => {
    setCourseMap((f) => {
      const newMap = { ...f }
      if (block.ineligible) {
        //set ineligible to false
        if (block.origin === 'CREDIT') {
          newMap.advancedStandings[index].ineligible = false
        } else {
          newMap.years[year].periods[periodIndex].blocks[
            index
          ].ineligible = false
        }
        //remove note if unedited
        if (block.note === 'Not eligible for transfer') {
          if (block.origin === 'CREDIT') {
            newMap.advancedStandings[index].note = null
          } else {
            newMap.years[year].periods[periodIndex].blocks[index].note = null
          }
        }
      } else {
        //set ineligible to true
        if (block.origin === 'CREDIT') {
          newMap.advancedStandings[index]['ineligible'] = true
        } else {
          newMap.years[year].periods[periodIndex].blocks[index][
            'ineligible'
          ] = true
        }
        //add note if none exists already
        if (!block.note) {
          if (block.origin === 'CREDIT') {
            newMap.advancedStandings[index]['note'] =
              'Not eligible for transfer'
          } else {
            newMap.years[year].periods[periodIndex].blocks[index]['note'] =
              'Not eligible for transfer'
          }
        }
      }

      autosave(newMap)
      return newMap
    })
  }

  //course labels

  // Block Ref
  // TODO: abstract other block refs
  const blockRef = (map) => {
    if (block.origin === 'CREDIT') {
      return map.advancedStandings[index]
    } else {
      return map.years[year].periods[periodIndex].blocks[index]
    }
  }

  const addCourseLabel = (item) => {
    if (editMode?.type === 'COURSE') {
      setInEditCourseMap((f) => {
        const newMap = deepCopy(f)
        blockRef(newMap).courseLabel = item
        return newMap
      })
    } else {
      setCourseMap((f) => {
        const newMap = { ...f }
        blockRef(newMap).courseLabel = item
        autosave(newMap)
        return newMap
      })
    }
  }

  const removeCourseLabel = (item) => {
    if (editMode?.type === 'COURSE') {
      setInEditCourseMap((f) => {
        const newMap = deepCopy(f)
        // check if label exists in current course map
        if (
          blockRef(currentCourseMap).courseLabel &&
          blockRef(currentCourseMap).courseLabel !==
            blockRef(newMap).courseLabel
        ) {
          // replace by label in the the current map
          blockRef(newMap).courseLabel = blockRef(currentCourseMap).courseLabel
        } else {
          delete blockRef(newMap).courseLabel
        }
        return newMap
      })
    } else {
      setCourseMap((f) => {
        const newMap = { ...f }
        delete blockRef(newMap).courseLabel
        autosave(newMap)
        return newMap
      })
    }
  }

  // toggle courseLabels

  const toggleCourseLabel = () => {
    block.courseLabel === editMode.code
      ? removeCourseLabel(editMode.code)
      : addCourseLabel(editMode.code)
  }

  // context menu

  const contextMenu = (e) => {
    e.preventDefault()

    const aos = courseMap?.aos?.filter(
      (item) => !block.aos?.some((x) => x === item)
    )

    // in advanced standings
    if (block.origin === 'CREDIT') {
      setMenu({
        id: block.id,
        position: { x: e.clientX, y: e.clientY },
        items: [
          block.name
            ? {
                name: 'View unit',
                icon: <Icon.Info />,
                fn: () => redirect(`browse/unit/${block.name}`),
              }
            : null,
          block.aos?.length
            ? {
                name: 'View area of study',
                icon: <Icon.Info />,
                list: block.aos.map((item) => {
                  return {
                    name: `View ${
                      item.code || item.title || item.name || item
                    }`,
                    icon: <Icon.Info />,
                    fn: () => redirect(`browse/aos/${item.code}`),
                  }
                }),
              }
            : null,
          !editMode && courseMap.courseLabels && !block.courseLabel
            ? {
                name: 'Assign course label',
                icon: <Icon.CodeAdd />,
                list: courseMap.courseLabels.map((item) => {
                  return {
                    name: `Add ${item}`,
                    icon: <Icon.CodeAdd />,
                    fn: () => addCourseLabel(item),
                  }
                }),
              }
            : null,
          !editMode && courseMap.courseLabels && block.courseLabel
            ? {
                name: 'Remove course label',
                icon: <Icon.CodeMinus />,
                fn: () => removeCourseLabel(block.courseLabel),
              }
            : null,
          !editMode && aos.length
            ? {
                name: 'Assign areas of study',
                icon: <Icon.SquarePlus />,
                list: aos.map((item) => {
                  return {
                    name: `Add ${item.code || item.name}`,
                    icon: <Icon.SquarePlus />,
                    fn: () => addAoS(item),
                  }
                }),
              }
            : null,
          !editMode && block.aos?.length
            ? {
                name: 'Remove area of study',
                icon: <Icon.SquareMinus />,
                list: block.aos.map((item) => {
                  return {
                    name: `Remove ${
                      item.code || item.title || item.name || item
                    }`,
                    icon: <Icon.SquareMinus />,
                    fn: () => removeAoS(item),
                  }
                }),
              }
            : null,
          {
            name: block.note ? 'Note' : 'Add note',
            icon: <Icon.Note />,
            fn: block.note ? null : addNote,
            list: block.note
              ? [
                  {
                    name: 'Edit note',
                    icon: <Icon.Pencil />,
                    fn: () => setEditNote(true),
                  },
                  { name: 'BREAK' },
                  {
                    name: 'Remove note',
                    icon: <Icon.FileX />,
                    className: c.red,
                    fn: removeNote,
                  },
                ]
              : null,
          },
          courseMap.transfer
            ? {
                name: `${block.ineligible ? 'Unmark' : 'Mark'} as ineligible`,
                icon: <Icon.Minus />,
                fn: () => {
                  toggleEligible()
                },
              }
            : null,
        ],
      })
    }

    // on map
    if (block.origin === 'MAP') {
      // unit
      if (block.blockType === 'UNIT') {
        setMenu({
          id: block.id,
          position: { x: e.clientX, y: e.clientY },
          items: [
            {
              name: 'View unit',
              icon: <Icon.Info />,
              fn: () => redirect(`browse/unit/${block.name}`),
            },

            block.aos?.length
              ? {
                  name: 'View area of study',
                  icon: <Icon.Info />,
                  list: block.aos.map((item) => {
                    return {
                      name: `View ${
                        item.code || item.title || item.name || item
                      }`,
                      icon: <Icon.Info />,
                      fn: () => redirect(`browse/aos/${item.code}`),
                    }
                  }),
                }
              : null,
            !editMode && courseMap.courseLabels && !block.courseLabel
              ? {
                  name: 'Assign course label',
                  icon: <Icon.CodeAdd />,
                  list: courseMap.courseLabels.map((item) => {
                    return {
                      name: `Add ${item}`,
                      icon: <Icon.CodeAdd />,
                      fn: () => addCourseLabel(item),
                    }
                  }),
                }
              : null,
            !editMode && courseMap.courseLabels && block.courseLabel
              ? {
                  name: 'Remove course label',
                  icon: <Icon.CodeMinus />,
                  fn: () => removeCourseLabel(block.courseLabel),
                }
              : null,

            !editMode && aos.length
              ? {
                  name: 'Assign areas of study',
                  icon: <Icon.SquarePlus />,
                  list: aos.map((item) => {
                    return {
                      name: `Add ${item.code || item.name}`,
                      icon: <Icon.SquarePlus />,
                      fn: () => addAoS(item),
                    }
                  }),
                }
              : null,
            !editMode && block.aos?.length
              ? {
                  name: 'Remove area of study',
                  icon: <Icon.SquareMinus />,
                  list: block.aos.map((item) => {
                    return {
                      name: `Remove ${
                        item.code || item.title || item.name || item
                      }`,
                      icon: <Icon.SquareMinus />,
                      fn: () => removeAoS(item),
                    }
                  }),
                }
              : null,
            !editMode
              ? {
                  name: block.note ? 'Note' : 'Add note',
                  icon: <Icon.Note />,
                  fn: block.note ? null : addNote,
                  list: block.note
                    ? [
                        {
                          name: 'Edit note',
                          icon: <Icon.Pencil />,
                          fn: () => setEditNote(true),
                        },
                        { name: 'BREAK' },
                        {
                          name: 'Remove note',
                          icon: <Icon.FileX />,
                          className: c.red,
                          fn: removeNote,
                        },
                      ]
                    : null,
                }
              : null,
            block.status !== 'COMPLETED' && { name: 'BREAK' },
            !editMode && block.status !== 'COMPLETED'
              ? locked
                ? {
                    name: (
                      <span>
                        Remove <b>{block.name}</b>
                      </span>
                    ),
                    icon: <Icon.Lock />,
                    className: c.grey,
                  }
                : {
                    name: (
                      <span>
                        Remove <b>{block.name}</b>
                      </span>
                    ),
                    icon: <Icon.Trash />,
                    className: c.red,
                    fn: removeBlock,
                  }
              : null,

            courseMap.transfer
              ? {
                  name: `${block.ineligible ? 'Unmark' : 'Mark'} as ineligible`,
                  icon: <Icon.Minus />,
                  fn: () => {
                    toggleEligible()
                  },
                }
              : null,
          ],
        })
      }

      // comment
      if (block.blockType === 'COMMENT') {
        setMenu({
          id: block.id,
          position: { x: e.clientX, y: e.clientY },
          items: [
            {
              name: 'Edit comment',
              icon: <Icon.Pencil />,
              fn: editComment,
            },
            block.aos?.length
              ? {
                  name: 'View area of study',
                  icon: <Icon.Info />,
                  list: block.aos.map((item) => {
                    return {
                      name: `View ${
                        item.code || item.title || item.name || item
                      }`,
                      icon: <Icon.Info />,
                      fn: () => redirect(`browse/aos/${item.code}`),
                    }
                  }),
                }
              : null,
            !editMode && courseMap.courseLabels && !block.courseLabel
              ? {
                  name: 'Assign course label',
                  icon: <Icon.CodeAdd />,
                  list: courseMap.courseLabels.map((item) => {
                    return {
                      name: `Add ${item}`,
                      icon: <Icon.CodeAdd />,
                      fn: () => addCourseLabel(item),
                    }
                  }),
                }
              : null,
            !editMode && courseMap.courseLabels && block.courseLabel
              ? {
                  name: 'Remove course label',
                  icon: <Icon.CodeMinus />,
                  fn: () => removeCourseLabel(block.courseLabel),
                }
              : null,

            aos.length
              ? {
                  name: 'Assign areas of study',
                  icon: <Icon.SquarePlus />,
                  list: aos.map((item) => {
                    return {
                      name: `Add ${item.code || item.name}`,
                      icon: <Icon.SquarePlus />,
                      fn: () => addAoS(item),
                    }
                  }),
                }
              : null,
            {
              name: 'Change size',
              icon: <Icon.Resize />,
              list: [
                { name: '1 Column', fn: () => changeSize(1) },
                { name: '2 Columns', fn: () => changeSize(2) },
                { name: '4 Columns', fn: () => changeSize(4) },
              ],
            },
            block.aos?.length
              ? {
                  name: 'Remove areas of study',
                  icon: <Icon.SquareMinus />,
                  list: block.aos.map((item) => {
                    return {
                      name: `Remove ${
                        item.code || item.title || item.name || item
                      }`,
                      icon: <Icon.SquareMinus />,
                      fn: () => removeAoS(item),
                    }
                  }),
                }
              : null,
            {
              name: block.note ? 'Note' : 'Add note',
              icon: <Icon.Note />,
              fn: block.note ? null : addNote,
              list: block.note
                ? [
                    {
                      name: 'Edit note',
                      icon: <Icon.Pencil />,
                      fn: () => setEditNote(true),
                    },
                    { name: 'BREAK' },
                    {
                      name: 'Remove note',
                      icon: <Icon.FileX />,
                      className: c.red,
                      fn: removeNote,
                    },
                  ]
                : null,
            },
            { name: 'BREAK' },
            {
              name: 'Remove comment',
              icon: <Icon.Trash />,
              className: c.red,
              fn: removeBlock,
            },
          ],
        })
      }
    }

    // on browser / pMAP
    if (block.origin === 'BROWSER' || block.origin === 'PMAP') {
      if (block.blockType === 'UNIT') {
        if (!block.elective) {
          setMenu({
            id: block.id,
            position: { x: e.clientX, y: e.clientY },
            items: [
              {
                name: 'View',
                icon: <Icon.Info />,
                fn: () =>
                  redirect(
                    `browse/unit/${block.name}${
                      selectedYear && `?year=${selectedYear}`
                    }`
                  ),
              },
            ],
          })
        }
      }
      //
      if (block.type === 'AOS' || block.type === 'COURSE') {
        if (!block.elective) {
          setMenu({
            id: block.id,
            position: { x: e.clientX, y: e.clientY },
            items: [
              {
                name: 'View',
                icon: <Icon.Info />,
                fn: () =>
                  redirect(
                    `browse/${block.type.toLowerCase()}/${block.name}${
                      selectedYear && `?year=${selectedYear}`
                    }`
                  ),
              },
            ],
          })
        }
      }
    }
  }

  // elective selection

  const [electiveSelection, setElectiveSelection] = useState(
    block.selectedElective || ''
  )

  const [previousElectiveSelection, setPreviousElectiveSelection] = useState(
    block.selectedElective || ''
  )

  useEffect(() => {
    if (block.selectedElective) setElectiveSelection(block.selectedElective)
  }, [block.selectedElective])

  useEffect(() => {
    if (electiveSelection !== previousElectiveSelection) {
      setPreviousElectiveSelection(electiveSelection)
      setCourseMap((f) => {
        const newMap = { ...f }

        newMap.pMaps = newMap.pMaps || {}
        newMap.pMaps[selectedPMap.code] = newMap.pMaps[selectedPMap.code] || {}
        newMap.pMaps[selectedPMap.code][selectedPMap.year] =
          newMap.pMaps[selectedPMap.code][selectedPMap.year] || []
        let index = 0
        if (newMap.pMaps[selectedPMap.code][selectedPMap.year].length > 0) {
          let exists = newMap.pMaps[selectedPMap.code][
            selectedPMap.year
          ].findIndex((p) => {
            return (
              p.filter_fields &&
              !selectedPMap.filter_fields.some((f) => selectedPMap[f] !== p[f])
            )
          })
          if (exists >= 0) {
            newMap.pMaps[selectedPMap.code][selectedPMap.year][exists] =
              selectedPMap
            index = exists
          } else {
            newMap.pMaps[selectedPMap.code][selectedPMap.year].push(
              selectedPMap
            )
            index =
              newMap.pMaps[selectedPMap.code][selectedPMap.year].length - 1
          }
        } else {
          newMap.pMaps[selectedPMap.code][selectedPMap.year] = [selectedPMap]
          index = 0
        }

        newMap.pMaps[selectedPMap.code][selectedPMap.year][index].map[
          block.year
        ].periods[block.periodIndex].units[block.index].selectedElective =
          electiveSelection

        let selectedId = electives.find(
          (item) => item.name === electiveSelection
        )?.id

        newMap.pMaps[selectedPMap.code][selectedPMap.year][index].map[
          block.year
        ].periods[block.periodIndex].units[block.index].electiveId =
          selectedId || null

        // newMap.pMaps[selectedPMap.code][selectedPMap.year][
        //   selectedPMap.area_of_study.name || 'Without major'
        // ] =
        //   newMap.pMaps[selectedPMap.code][selectedPMap.year][
        //     selectedPMap.area_of_study.name || 'Without major'
        //   ] || selectedPMap

        // const pMapRef =
        //   newMap.pMaps[selectedPMap.code][selectedPMap.year][
        //     selectedPMap.area_of_study.name || 'Without major'
        //   ]

        // pMapRef.code = selectedPMap.code
        // pMapRef.year = selectedPMap.year
        // pMapRef.download_url = selectedPMap.download_url

        // pMapRef.years[block.year].teaching_periods[block.periodIndex].units[
        //   block.index
        // ].selectedElective = electiveSelection

        autosave(newMap)
        return newMap
      })
    }
  }, [
    autosave,
    block,
    electiveSelection,
    previousElectiveSelection,
    selectedPMap,
    setCourseMap,
    electives,
  ])

  // status

  const status = useCallback(() => {
    // elective
    if (block.elective) {
      const electiveFound = electives.find(
        (item) => item.name === electiveSelection
      )
      const electiveStatus = electiveFound?.status
      if (electiveStatus) {
        block.status = electiveStatus
        if (electiveStatus === 'COMPLETED') {
          const electiveGrade = electiveFound?.grade
          return (
            <div
              className={!isPass(electiveGrade) ? c.gradedFail : c.gradedPass}>
              <span>Graded</span>
              <span>{electiveGrade}</span>
            </div>
          )
        }

        if (electiveStatus === 'ENROLLED')
          return <div className={c.enrolled}>Enrolled</div>
        if (electiveStatus === 'NOT OFFERED')
          return <div className={c.notOffered}>Not offered</div>
        if (electiveStatus === 'SUGGESTED')
          return <div className={c.suggested}>Suggested</div>
        if (electiveStatus === 'DISCONTIN')
          return <div className={c.discontinued}>Discontinued</div>
        if (electiveStatus === 'IN PLAN')
          return (
            <div className={c.granted}>
              {capitalise(electiveFound.category) || 'Credit'} {'\u2013'}{' '}
              {electiveFound.grantingStatus?.toLowerCase() || 'granted'}
            </div>
          )

        if (electiveStatus === 'DUPLICATE') {
          const electiveGrade = electives.find(
            (item) => item.name === electiveSelection
          )?.grade
          if (electiveGrade) {
            return (
              <div
                className={electiveGrade === 'N' ? c.gradedFail : c.gradedPass}>
                <span>Duplicate</span>
                <span>{electiveGrade}</span>
              </div>
            )
          } else {
            return <div className={c.duplicate}>Duplicate</div>
          }
        }
        if (electiveStatus === 'UNCONFIRM')
          return <div className={c.unconfirm}>Unconfirm</div>
        if (electiveStatus === 'INVALID')
          return <div className={c.invalid}>Invalid</div>
      }
    }

    if (block.status === 'COMPLETED')
      return (
        <div className={!isPass(block.grade) ? c.gradedFail : c.gradedPass}>
          <span>Graded</span>
          <span>{block.grade}</span>
        </div>
      )

    if (block.status === 'ENROLLED')
      return <div className={c.enrolled}>Enrolled</div>
    if (block.status === 'NOT OFFERED')
      return <div className={c.notOffered}>Not offered</div>
    if (block.status === 'SUGGESTED')
      return <div className={c.suggested}>Suggested</div>
    if (block.status === 'DISCONTIN')
      return <div className={c.discontinued}>Discontinued</div>
    if (block.status === 'DUPLICATE') {
      if (block.grade) {
        return (
          <div className={block.grade === 'N' ? c.gradedFail : c.gradedPass}>
            <span>Duplicate</span>
            <span>{block.grade}</span>
          </div>
        )
      } else {
        return <div className={c.duplicate}>Duplicate</div>
      }
    }
    if (block.status === 'UNCONFIRM')
      return <div className={c.unconfirm}>Unconfirm</div>
    if (block.status === 'INVALID')
      return <div className={c.invalid}>Invalid</div>

    // Credit

    // if (block.grantingStatus === 'GRANTED') {
    //   return (
    //     <div className={c.granted}>{capitalise(block.category)} \u2013 granted</div>
    //   )
    // }
    // if (block.grantingStatus === 'APPROVED') {
    //   return (
    //     <div className={c.granted}>{capitalise(block.category)} \u2013 approved</div>
    //   )
    // }
    if (block.status === 'IN PLAN')
      return (
        <div className={c.inPlanStatus}>
          {capitalise(block.category) || 'Credit'} {'\u2013'}{' '}
          {block.grantingStatus?.toLowerCase() || 'granted'}
        </div>
      )
  }, [block, electiveSelection, electives])

  // class name

  const cName = useCallback(() => {
    const list = [c.block]
    if (block.elective && status()) {
      let electiveStatus = status().props.children
      if (electiveStatus === 'Suggested') list.push(c.suggested)
      if (electiveStatus === 'Graded') list.push(c.graded)
      if (electiveStatus === 'Duplicate') list.push(c.graded)
      if (electiveStatus === 'Enrolled') list.push(c.enrolled)
      if (electiveStatus === 'Credit \u2013 granted') list.push(c.inPlan)
      if (electiveStatus === 'Discontinued') list.push(c.discontinued)
      if (electiveStatus.constructor === Array) {
        if (
          electiveStatus[0] &&
          electiveStatus[1] &&
          ['Credit', 'Preclusion'].includes(electiveStatus[0]) &&
          ['granted', 'approved'].includes(
            electiveStatus[electiveStatus.length - 1]
          )
        ) {
          list.push(c.inPlan)
        } else if (
          electiveStatus[0] &&
          electiveStatus[1] &&
          electiveStatus[0].props?.children === 'Graded' &&
          electiveStatus[1].props?.children === 'N'
        ) {
          list.push(c.gradedFail)
        }
      }
    }
    if (block.id === selected?.id && !fake) list.push(c.selected)
    if (block.origin === 'MAP') list.push(c.map)
    if (block.origin === 'PMAP') list.push(c.pMap)

    if (disabled) list.push(c.disabled)
    if (block.origin === 'BROWSER') list.push(c.browser)
    if (block.origin === 'CREDIT') list.push(c.credit)
    if (block.status === 'NEW') list.push(c.newBlock)
    if (block.status === 'SUGGESTED') list.push(c.suggested)

    if (block.status === 'COMPLETED') {
      if (block.grade === 'N') {
        list.push(c.gradedFail)
      } else list.push(c.graded)
    }
    if (block.status === 'DUPLICATE') list.push(c.graded)
    if (block.status === 'ENROLLED') list.push(c.enrolled)
    if (block.status === 'IN PLAN') list.push(c.inPlan)
    if (block.status === 'NOMINATED') list.push(c.nominated)
    if (block.type === 'AOS') list.push(c.aos)
    if (block.blockType === 'COMMENT') list.push(c.comment)
    if (block.elective && fake) list.push(c.comment)
    if (editMode) list.push(c.editMode)

    if (filler) list.push(c.filler)

    return list.join(' ')
  }, [
    block.blockType,
    block.elective,
    block.grade,
    block.id,
    block.origin,
    block.status,
    block.type,
    disabled,
    editMode,
    fake,
    filler,
    selected?.id,
    status,
  ])

  // description

  const description = () => {
    if (block.elective) {
      const electiveFound = electives.find(
        (item) =>
          item.blockType !== 'COMMENT' &&
          item.blockType !== 'INTERMISSION' &&
          item.name === block.selectedElective
      )

      if (!displayOptions.showDescription && block.selectedElective) return null

      if (electiveFound?.description) return electiveFound.description

      if (electiveFound?.creditType === 'unspecifiedCredit')
        return electiveFound.name
    }

    if (block.origin === 'MAP' || block.blockType === 'CREDIT') {
      if (block.blockType !== 'COMMENT' && displayOptions.showDescription) {
        if (block.description) {
          return block.description
        }
      }
    }

    if (
      displayOptions.showDescription ||
      block.blockType === 'COMMENT' ||
      !block.name
    ) {
      return (
        block.description ||
        `Level ${block.unitLevel} ${capitalise(block.disciplineDescription)}`
      )
    }

    return
  }

  const aosTags = () => {
    return (
      <ul className={`${c.aos} ${condenseMode ? c.condense : null}`}>
        {block.aos.map((item, i) => {
          return (
            <AosTag key={i} aos={item}>
              {item.code || item}
            </AosTag>
          )
        })}
      </ul>
    )
  }

  // display options
  if (hide) return null

  // render fake

  if (fake)
    return (
      <div className={cName()} style={style}>
        <div className={`${c.content} ${condenseMode ? c.condense : null}`}>
          {/* course label */}
          {block.courseLabel && (
            <CourseLabel
              origin={block.origin}
              blockType={block.blockType}
              label={block.courseLabel}
            />
          )}
          {block.name && <h4>{block.name}</h4>}
          <div
            className={`${c.description} ${condenseMode ? c.condense : null}`}>
            {displayOptions.showDescription ? block.description : null}
            {!displayOptions.showDescription && !block.name
              ? block.description
              : null}
          </div>
          {/* if aos applied */}
          {block.aos?.length > 0 && aosTags()}
          {/* Credit points tag and note tag */}
          <div className={c.tags}>
            <CreditPointsTag
              blockType={block.blockType}
              origin={block.origin}
              creditPoints={block.creditPoints}
              elective={block.elective}
              selectedElective={block.selectedElective}
              electives={electives}
            />
            <NoteTag note={block.note} showNotes={displayOptions.showNotes} />
          </div>
          {block.status ? (
            <div className={`${c.status} ${condenseMode ? c.condense : null}`}>
              {status()}
            </div>
          ) : null}
        </div>
        {/* note */}
        {block.note && displayOptions.showNotes && (
          <Note
            note={block.note}
            {...{ block, editNote, setEditNote, index, periodIndex, year }}
          />
        )}
      </div>
    )
  // render
  return (
    <Drag
      id={block.id}
      className={cName()}
      data={{ ...block, year, periodIndex, index }}
      disabled={disabled || editMode || commentEditMode}
      handleClass={editMode ? null : c.handle}
      style={{
        ...style,
        gridColumn: block.size
          ? block.size === 4
            ? `1 / -1`
            : `span ${block.size}`
          : `span 1`,
      }}
      contextMenu={contextMenu}>
      {/* ineligible overlay */}
      {block.name && block.origin !== 'CREDIT' && (
        <span onClick={contextMenu} className={c.menuIcon}>
          <Icon.DotsVertical />
        </span>
      )}
      {block.ineligible && <div className={c.ineligible} />}
      <div className={`${c.content} ${condenseMode ? c.condense : null}`}>
        {/* meta top */}
        <div className={c.metaTop}>
          <span className={c.type}>{block.blockType}</span>
          {block.creditPoints > 0 && (
            <span className={c.cp}> &bull; {block.creditPoints}CP</span>
          )}
          {block.status === 'NOMINATED' && (
            <span className={c.nominated}> NOMINATED</span>
          )}
        </div>
        {/* course label */}
        {block.courseLabel && (
          <CourseLabel
            origin={block.origin}
            blockType={block.blockType}
            label={block.courseLabel}
          />
        )}
        {/* name if unit code exists */}
        {block.name && (
          <h4>
            <span>{block.name}</span>{' '}
          </h4>
        )}
        {/* elective selector */}
        {block.elective && block.origin === 'PMAP' && (
          <div className={c.selector}>
            <select
              className={block.selectedElective ? c.withSelection : null}
              value={block.selectedElective}
              onChange={(e) => setElectiveSelection(e.target.value)}>
              <option default value={''}>
                Select unit
              </option>
              {electives
                .filter((item) => item.name)
                .filter((item) => {
                  return (
                    item.name === block.selectedElective ||
                    (item.creditType === 'unspecifiedCredit'
                      ? !item.allocated
                      : !selectedPMap.map.some((year) =>
                          year.periods.some((tp) =>
                            tp.units.some(
                              (u) => u.selectedElective === item.name
                            )
                          )
                        ))
                  )
                })
                .sort((a, b) => {
                  if (a.creditType === 'unspecifiedCredit') return 1
                  if (b.creditType === 'unspecifiedCredit') return -1
                  if (a.name > b.name) return 1
                  if (a.name < b.name) return -1
                  return 0
                })
                .map((item, i) => (
                  <option value={item.name} key={i}>
                    {item.displayName || item.name}
                  </option>
                ))}
            </select>
          </div>
        )}
        {/* description */}
        <div className={`${c.description} ${condenseMode ? c.condense : null}`}>
          {!commentEditMode && description()}
          {commentEditMode && block.blockType === 'COMMENT' && (
            <TextArea
              className={c.commentTextarea}
              ref={commentRef}
              onKeyDown={handleKeypress}
              autoFocus={true}
              onBlur={saveEdit}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          )}
        </div>

        {/* if aos applied */}
        {block.aos?.length > 0 && aosTags()}
        {/* Credit points tag and note tag */}
        {/* TODO: turn tags into a component */}
        <div className={c.tags}>
          <CreditPointsTag
            blockType={block.blockType}
            origin={block.origin}
            creditPoints={block.creditPoints}
            elective={block.elective}
            selectedElective={block.selectedElective}
            electives={electives}
          />
          <NoteTag note={block.note} showNotes={displayOptions.showNotes} />
        </div>
        {/* elective selected */}
        {block.selectedElective && (
          <div className={c.electiveDescription}>{block.description}</div>
        )}
        {/* offering meta */}
        {/* <div className={c.metaBottom}>
          <span className={c.offering}>{block.offering}</span>
        </div> */}
        {/* status */}
        {block.status && !block.elective ? (
          <div className={`${c.status} ${condenseMode ? c.condense : null}`}>
            {status()}
          </div>
        ) : null}
        {block.eligible && (
          <span className={c.eligible}>
            Eligible units: {block.eligible.length}
          </span>
        )}
        {block.elective && status() ? (
          <div className={`${c.status} ${condenseMode ? c.condense : null}`}>
            {status()}
          </div>
        ) : null}
      </div>
      {/* note */}
      {block.note &&
        block.id !== selected?.id &&
        (displayOptions.showNotes || editNote) && (
          <Note
            note={block.note}
            {...{ block, editNote, setEditNote, index, periodIndex, year }}
          />
        )}
      {/* toggle */}
      {editMode && (block.origin === 'MAP' || block.origin === 'CREDIT') ? (
        <Toggle
          onClick={editMode.type === 'AOS' ? toggleAoS : toggleCourseLabel}
          on={
            editMode.type === 'AOS'
              ? block.aos?.some((item) => item.code === editMode.code)
              : block.courseLabel === editMode.code
          }
        />
      ) : null}
    </Drag>
  )
}

export default Block
