import { getFetchOptions, call, endpointPrefix } from './callApi'
import { capitalise } from './transforms/string-transforms'

const transformCourseEnrolments = (
  studentV2,
  advancedStanding,
  fullYearExceptions
) => {
  const apiCourseEnrolments = [...(studentV2.courseEnrolments?._items || [])]
  apiCourseEnrolments.forEach((e) => {
    if (!e.courseCode && e.id) {
      e.courseCode = e.id
    }
    if (!e.courseVersionNumber && e.course) {
      e.courseVersionNumber = e.course.version
    }
  })

  const apiUnitEnrolments = [...(studentV2.studyUnitEnrolments?._items || [])]
  apiUnitEnrolments
    .filter((e) => e['status'] === 'DUPLICATE' && e['unitOfferingId'])
    .forEach((e) => {
      e.duplicateOf = apiUnitEnrolments.filter(
        (de) =>
          de['unitOfferingId'] === e['unitOfferingId'] &&
          de['status'] !== 'DUPLICATE'
      )
    })

  const result = apiCourseEnrolments.map((i) => {
    i.isNew = true
    if (!i.enrolmentYear && i.enrolmentDate) {
      i.enrolmentYear = i.enrolmentDate.split('-')[0]
    }
    i.removed = false
    return i
  })

  result.forEach((e) => {
    let apiCourseUnitEnrolments = apiUnitEnrolments.filter(
      (i) => i.courseCode === e.courseCode
    )

    let courseTeachingPeriods = {}

    apiCourseUnitEnrolments.forEach((u) => {
      const academicYearPeriod = `${u.teachingPeriod.academicYear}-${u.teachingPeriod.calendarType.code}`
      if (!courseTeachingPeriods[academicYearPeriod]) {
        courseTeachingPeriods[academicYearPeriod] = {
          teachingPeriod: u.teachingPeriod,
          unitEnrolments: {},
        }
      }
      courseTeachingPeriods[academicYearPeriod].unitEnrolments[
        u.studyUnit.unitCode
      ] = u
    })

    e.teachingPeriods = courseTeachingPeriods
  })

  advancedStanding = [...(advancedStanding || [])]

  advancedStanding.forEach((i) => {
    if (
      result.filter(
        (e) =>
          i.courseCode === e.courseCode &&
          i.courseVersionNumber === e.courseVersionNumber
      ).length <= 0
    ) {
      result.push({
        courseCode: i.courseCode,
        courseVersionNumber: i.courseVersionNumber,
      })
    }
  })

  result.forEach((e) => {
    if (e.courseCode && e.courseVersionNumber) {
      e.advancedStandings = advancedStanding.filter(
        (i) =>
          i.courseCode === e.courseCode &&
          i.courseVersionNumber === e.courseVersionNumber
      )
    }
  })

  //full year exceptions

  fullYearExceptions = [...(fullYearExceptions || [])]

  fullYearExceptions.forEach((i) => {
    result.forEach((e) => {
      if (e.courseCode === i.courseCode && !e.fullYearExceptions) {
        e.fullYearExceptions = [i]
      } else if (e.courseCode === i.courseCode && e.fullYearExceptions) {
        e.fullYearExceptions.push(i)
      }
    })
  })
  return result
}

const getIdXref = async (key, value) => {
  const idxrefUrl = `${endpointPrefix}/mix-api/proxy/api/idxref/v1/id-xref-v2?${key}=${value}`
  const options = await getFetchOptions('GET')
  const mixResponse = await call(idxrefUrl, options)
  if (
    mixResponse.results &&
    mixResponse.results.length > 0 &&
    mixResponse.results[0].identifiers
  ) {
    return mixResponse.results[0].identifiers
  } else {
    const error = new Error(
      'This account is strange according to MIX response.'
    )
    error.mixResponse = mixResponse
    throw error
  }
}

const transformName = (personName) => {
  const names = { ...personName }
  names['firstName'] = 
    personName.knownAs ? capitalise(personName.knownAs)
      : personName.givenNames.map((x) => x.split(' ')
      .filter(n => n !== '-')
      .map(capitalise).join(' '))
      .join(' ').trim()
  names['fullName'] = 
    personName.knownAs ?
      personName.knownAs.includes(personName.familyName) ?
        capitalise(personName.knownAs)
        : capitalise(personName.knownAs) + ' ' + capitalise(personName.familyName)
      : names.firstName + ' ' + capitalise(personName.familyName)
  return names
}

const getStudent = async (studentNo, includes) => {
  const query =
    includes && includes.length > 0 ? `?include=${includes.join(',')}` : ''
  const mixUrl = `${endpointPrefix}/mix-api/student-portal/v1/students/${studentNo}${query}`
  const options = await getFetchOptions('GET')
  const mixResponse = await call(mixUrl, options)
  return mixResponse
}

const getCredits = async (studentNo) => {
  const mixUrl = `${endpointPrefix}/mix-api/student-portal/v1/students/${studentNo}/credits`
  const options = await getFetchOptions('GET')
  const mixResponse = await call(mixUrl, options)
  return mixResponse?.credits || []
}

const getFullYearExceptions = async (studentNo) => {
  const mixUrl = `${endpointPrefix}/mix-api/student-portal/v1/students/${studentNo}/full-year-exceptions`
  const options = await getFetchOptions('GET')
  const mixResponse = await call(mixUrl, options)
  return mixResponse?.fullYearExceptions || []
}

const getStudentDetails = async (studentNo) => {
  const includes = [
    'courseEnrolments',
    'course',
    'studyUnitEnrolments',
    'studyUnit',
    'teachingPeriod',
    'location',
    'studyUnitSetEnrolments',
    'notes',
    'intermissions',
  ]
  const [studentV2, credits, fullYearExceptions, identifiers] =
    await Promise.all([
      getStudent(studentNo, includes),
      getCredits(studentNo),
      getFullYearExceptions(studentNo),
      getIdXref('callistaPersonID', studentNo),
    ])
  const data = {}
  data['identifiers'] = identifiers
  data['citizenship'] = studentV2.citizenship
  data['courseEnrolments'] = transformCourseEnrolments(
    studentV2,
    credits,
    fullYearExceptions
  )
  data['personName'] = transformName(studentV2.personName)
  data['studentIndicatorLamp'] = studentV2.studentIndicatorLamp
  return data
}

const getStudentInformation = async (studentNo) => {
  const includes = ['courseEnrolments', 'course', 'studyUnitSetEnrolments']
  const [studentV2, identifiers] = await Promise.all([
    getStudent(studentNo, includes),
    getIdXref('callistaPersonID', studentNo),
  ])
  const data = {}
  data['identifiers'] = identifiers
  data['citizenship'] = studentV2.citizenship
  data['courseEnrolments'] = transformCourseEnrolments(studentV2)
  data['personName'] = transformName(studentV2.personName)
  data['studentIndicatorLamp'] = studentV2.studentIndicatorLamp
  return data
}

export { getStudentDetails, getStudentInformation }
