import React, { useContext } from 'react'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { courseLabelColors } from 'utils/colors/course-label-colors'
import c from './course-label.module.scss'

const plannerOrigins = ['MAP', 'CREDIT']
const browserOrigins = ['PMAP', 'BROWSER']

const CourseLabel = ({ blockType, origin, label }) => {
  const { courseMap, selectedPMapCourseLabels } = useContext(Data)
  const { courseLabels } = courseMap || {}

  // Hide individual course label if no course labels are applied to map
  if (plannerOrigins.includes(origin) && !courseLabels?.includes(label)) {
    return null
  }

  const colourIndex =
    browserOrigins.includes(origin) && selectedPMapCourseLabels
      ? selectedPMapCourseLabels?.indexOf(label)
      : courseLabels?.indexOf(label)

  const lightColour = courseLabelColors[colourIndex]?.light
  const darkColour = courseLabelColors[colourIndex]?.dark

  return (
    <div
      className={c.courseLabel}
      style={{
        color: `${blockType === 'COMMENT' ? lightColour : darkColour}`,
      }}>
      {label}
    </div>
  )
}

export default CourseLabel
