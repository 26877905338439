import React, {
  useEffect,
  useState,
  useContext,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react'
import c from './content.module.scss'
import Handbook from './pages/handbook/Handbook'
import Overview from './pages/overview/Overview'
import ProgressionMap from './pages/progression-map/ProgressionMap'
import { TutorialContext } from 'components/utilities/tutorial/TutorialProvider'
import Rules from './pages/rules/Rules'
import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { RouterState } from 'utils/Router'
import Icon from '../../utilities/icons/Icon'
import { CurrentStudent } from 'components/wrappers/student-data-wrapper/StudentDataWrapper'
import Tabs from 'components/utilities/tabs/Tabs'
import PartialLoadingScreen from 'components/utilities/loading-indicator/PartialLoadingScreen'
import LoadingIndicator from 'components/utilities/loading-indicator/LoadingIndicator'
import { capitalise } from 'utils/transforms/string-transforms'
import { years, defaultYear } from 'utils/data/years'
import { BrowserData } from '../Browser'

export const ContentContext = React.createContext()

const Content = ({ pageData, callista }) => {
  // redirect if no plan selected
  const { selectedPlan } = useContext(Data)
  const { redirect } = useContext(RouterState)
  const { bookmarks, addBookmark, removeBookmark, currentStudent } =
    useContext(CurrentStudent)
  const { historyValues, updateHistory } = useContext(BrowserData)
  const [loading, setLoading] = useState(false)

  // meta
  const [meta, setMeta] = useState({
    type:
      (pageData?.type === 'Area of Study'
        ? 'aos'
        : pageData?.type.toLowerCase()) || '',
    category:
      pageData?.category ||
      (pageData?.type === 'aos'
        ? 'Area of Study'
        : capitalise(pageData?.type)) ||
      '',
    code: pageData?.code || '',
    title: pageData?.title || callista[Object.keys(callista)[0]]?.title || '',
    faculty:
      pageData?.faculty ||
      callista[Object.keys(callista)[0]]?.managingFacultyName ||
      'faculty of arts',
    year: pageData?.implementationYear || defaultYear,
  })

  useEffect(() => {
    setMeta({
      type:
        (pageData?.type === 'Area of Study'
          ? 'aos'
          : pageData?.type.toLowerCase()) || '',
      category:
        pageData?.category ||
        (pageData?.type === 'aos'
          ? 'Area of Study'
          : capitalise(pageData?.type)) ||
        '',
      code: pageData?.code || '',
      title: pageData?.title || callista[Object.keys(callista)[0]]?.title || '',
      faculty:
        pageData?.faculty ||
        callista[Object.keys(callista)[0]]?.managingFacultyName ||
        'faculty of arts',
      year: pageData?.implementationYear || defaultYear,
    })
    setLoading(false)
  }, [pageData, callista])

  // banner
  const [bannerImageSrc, setBannerImageSrc] = useState()

  useEffect(() => {
    import(
      `assets/images/faculty-banners/${meta.faculty
        .toLowerCase()
        .replace(/ /g, '_')}.jpg`
    )
      .then((item) => setBannerImageSrc(item.default))
      .catch(() => {
        import(`assets/images/faculty-banners/faculty_of_arts.jpg`)
          .then((item) => setBannerImageSrc(item.default))
          .catch((e) => console.log(e))
      })
  }, [meta.faculty])

  // tabs
  const [activeTab, setActiveTab] = useState(historyValues?.activeTab || 0)

  useEffect(() => {
    if (historyValues) {
      setActiveTab(historyValues.activeTab)
    }
  }, [historyValues])

  const currentCourse = currentStudent?.courseEnrolments?.filter((x) => 
    x.courseCode === pageData?.code 
    && (selectedPlan?.transfer || x.courseCode === selectedPlan?.code)
  )[0]

  const tabs = [
    ...(currentCourse
      ? [
          {
            name: 'Student overview',
            component: (
              <Overview pageData={pageData} currentCourse={currentCourse} />
            ),
          },
        ]
      : []),
    ...(pageData?.type.toLowerCase() === 'course'
      ? [
          {
            name: 'Progression map',
            component: (
              <ProgressionMap
                pageData={pageData}
                currentCourse={currentCourse}
              />
            ),
          },
        ]
      : []),
    {
      name: 'Handbook',
      component: <Handbook pageData={pageData} callista={callista} />,
    },
    ...(Object.keys(callista).length > 0
      ? [
          {
            name: 'Callista rules',
            component: <Rules callista={callista} type={meta.category} />,
          },
        ]
      : []),
  ]

  const changeTab = (clickedTab) => {
    setActiveTab(clickedTab)
    updateHistory((page) => {
      page['activeTab'] = clickedTab
      return page
    })
  }

  //tutorial
  const { updateRef } = useContext(TutorialContext)
  const containerRef = useRef()

  useLayoutEffect(() => {
    updateRef('Information panel', containerRef.current)
  }, [updateRef])

  //populate years based on callista rules
  const [validYears, setValidYears] = useState(years)
  useEffect(() => {
    const first = Object.keys(callista).sort(
      (a, b) => parseInt(a) - parseInt(b)
    )[0]
    if (first) {
      const startYear = callista[first].startDate.slice(0, 4)
      let index = years.indexOf(startYear)
      setValidYears(index >= 0 ? years.slice(0, index + 1) : years)
    }
  }, [callista])

  const changeYear = (year) => {
    setLoading(true)
    //  pageData.type === 'Area of Study' ? 'aos' : pageData.type.toLowerCase()
    redirect(`/browse/${meta.type}/${pageData.code}?year=${year}`, {
      type: meta.type,
      code: pageData.code,
      year: year,
    })
  }

  const bookmarkButton = () => {
    if (bookmarks.some((b) => b.code === meta.code)) {
      return (
        <div className={c.removeBookmarkButton}>
          <button onClick={() => removeBookmark(meta.code)}>
            <Icon.Bookmark />
          </button>
        </div>
      )
    } else {
      return (
        <div className={c.addBookmarkButton}>
          <button
            onClick={() =>
              addBookmark({
                type: meta.type,
                code: meta.code,
                year: meta.year,
                title: meta.title,
              })
            }>
            <Icon.Bookmark />
          </button>
        </div>
      )
    }
  }

  // previous page
  const [prevPage, setPrevPage] = useState()

  const previousPage = useCallback(() => {
    const url = window.history.state?.from.split('/')
    let display = ''
    let year = defaultYear

    if (!url?.includes('browse')) {
      // /plans
      if (url?.includes('plans')) {
        let name =
          currentStudent.personName?.firstName || 'Student'
        display = `${name}'s plans`
      } else {
        display = capitalise(url?.[1]?.split('?')?.[0] || '')
      }
    } else if (url?.length >= 4) {
      // /browse/course/B2000?year=2022
      let target = url[3]?.split('?')
      display = target[0]
      year = target.length > 1 ? target[1].split('=')[1] : defaultYear
    } else if (url?.length >= 2) {
      // /browse
      display = selectedPlan?.code
      year = selectedPlan?.startingYear
    }

    if (display === meta.code) {
      display += ` (${year})`
    }
    return display || 'Back to previous page'
  }, [currentStudent, meta, selectedPlan])

  useEffect(() => {
    setPrevPage(previousPage())
  }, [previousPage])

  // render
  return (
    <ContentContext.Provider value={{ selectedYear: meta.year }}>
      <div className={c.content} ref={containerRef} id='information-panel'>
        {window.history.state?.from && prevPage && (
          <div className={c.previousPage}>
            <span onClick={() => window.history.back()}>
              <Icon.ArrowLeft />
            </span>
            <span onClick={() => window.history.back()}>{prevPage}</span>
          </div>
        )}
        {/* header */}
        <header>
          <div className={c.banner}>
            {meta.type === 'course' ? (
              <>
                {bannerImageSrc ? (
                  <img src={bannerImageSrc} alt={meta.faculty} />
                ) : (
                  <div className={c.loading}>
                    <LoadingIndicator size={30} />
                  </div>
                )}
                <label>{meta.type}</label>
              </>
            ) : (
              <div className={c.labelAlt}>{meta.category}</div>
            )}
          </div>
          <div className={c.title}>
            <h1>
              {meta.code} {'\u2013'} {meta.title}
            </h1>
            <div className={c.actionButtons}>
              <fieldset>
                <select
                  value={meta.year}
                  onChange={(e) => changeYear(e.target.value)}>
                  {validYears.map((y) => {
                    return (
                      <option key={y} value={y}>
                        {y}
                      </option>
                    )
                  })}
                </select>
                <div className={c.dropdown}>
                  {meta.year} <Icon.ChevronDown />
                </div>
              </fieldset>
              {bookmarkButton()}
            </div>
          </div>
        </header>
        {/* tabs */}

        <Tabs onChange={changeTab} tabs={tabs} activeKey={activeTab}></Tabs>
        {/* content */}
        <PartialLoadingScreen loaded={!loading}>
          <div className={c.activeTabContent}>
            {tabs[activeTab]?.component || tabs[0].component}
          </div>
        </PartialLoadingScreen>
      </div>
    </ContentContext.Provider>
  )
}

export default Content
