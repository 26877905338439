import { Data } from 'components/wrappers/course-map-data-wrapper/CourseMapDataWrapper'
import { Interface } from 'components/wrappers/interface-data-wrapper/InterfaceDataWrapper'
import React, { useContext, useEffect, useState } from 'react'
import c from './search-aos.module.scss'
import { makeSubstringBold } from 'utils/StringUtil'
import deepCopy from 'utils/edit-map/deep-copy-map'
import Icon from 'components/utilities/icons/Icon'

const SearchAoS = ({ searchingAOS, setSearchingAOS }) => {
  // AoS data
  const { eligibleAoS, setInEditCourseMap, courseMap } = useContext(Data)
  const { setEditMode, setUnitsToAdd } = useContext(Interface)
  const [sortedAoS, setSortedAoS] = useState([])

  useEffect(() => {
    if (eligibleAoS) {
      let selected = courseMap?.aos?.map((aos) => aos.code) || []
      const list = Object.keys(eligibleAoS)
        .filter((key) => !selected.includes(key))
        .map((key) => {
          let aos = eligibleAoS[key]
          let name = `
          ${aos.type.slice(0, 2).toUpperCase()} 
          \u2013 ${aos.code} ${aos.title}`
          return {
            code: aos.code,
            name: name,
            start: aos.start,
            units: eligibleAoS[key].units,
          }
        })

      setSortedAoS(list.sort((a, b) => b.units.length - a.units.length))
    }
  }, [eligibleAoS, courseMap])

  // search
  const [searchTerm, setSearchTerm] = useState('')
  const [suggested, setSuggested] = useState([])
  const [validAoS, setValidAoS] = useState(false)

  useEffect(() => {
    const rx = new RegExp(searchTerm.replace(/\\/g, ''), 'i')
    setSuggested(sortedAoS.filter((item, i) => rx.test(item.name)))
    if (searchTerm)
      setValidAoS(
        sortedAoS.some((item) => {
          return item?.code?.toLowerCase() === searchTerm.toLowerCase()
        })
          ? true
          : false
      )
  }, [searchTerm, sortedAoS])

  const addAoS = (selected) => {
    //e.preventDefault()
    //if (searchTerm) {

    setSearchingAOS(false)
    const aos = selected.toUpperCase()
    setInEditCourseMap(() => {
      const newMap = deepCopy(courseMap)
      //const aos = searchTerm.toUpperCase()

      const units = {}

      newMap.advancedStandings.forEach((block, i) => {
        if (eligibleAoS[aos]?.units.some((item) => item === block.name)) {
          const set = block.aos
            ? new Set([...block.aos, eligibleAoS[aos]])
            : [eligibleAoS[aos]]
          block.aos = [...set]
          units[block.name] = true
        }
      })

      Object.values(newMap.years).forEach((year) =>
        year.periods.forEach((period) =>
          period.blocks.forEach((block, i) => {
            if (eligibleAoS[aos]?.units.some((item) => item === block.name)) {
              const set = block.aos
                ? new Set([...block.aos, eligibleAoS[aos]])
                : [eligibleAoS[aos]]
              block.aos = [...set]
            }
          })
        )
      )

      const list = new Set([
        ...newMap.aos,
        sortedAoS.filter((a) => a.code === aos /*searchTerm*/)[0],
      ])

      newMap.aos = [...list]

      setUnitsToAdd(units)
      setEditMode({ type: 'AOS', code: aos })
      setSearchTerm('')
      setValidAoS(false)
      return newMap
    })
    //}
  }

  // render

  return (
    <div className={c.search}>
      {searchingAOS ? (
        <div className={c.header}>
          <label>Add areas of study</label>
          <button onClick={() => setSearchingAOS(false)}>
            Cancel
            <Icon.X />
          </button>
        </div>
      ) : null}
      {searchingAOS ? (
        <form onSubmit={(e) => e.preventDefault() /*addAoS*/}>
          <input
            autoFocus
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={'Search'}
          />
          {validAoS && <button>Add</button>}
        </form>
      ) : null}
      <ul className={c.suggested}>
        {suggested.length ? (
          <span className={c.listHeader}>
            <span className={c.secondary}>
              Type - area of study - starting year
            </span>
            <span className={c.number}>
              <span className={c.secondary}>Eligible units </span>
            </span>
          </span>
        ) : null}
        {suggested.map((item, i) => (
          <li
            key={i}
            onClick={(e) => addAoS(item.code) /*setSearchTerm(item.code)*/}>
            <span className={c.name}>
              {makeSubstringBold(searchTerm, item.name)} {'\u2013'} {item.start}
            </span>
            <span className={c.number}>{item.units.length}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SearchAoS
