import CourseLabel from 'components/planner/mapper/blocks/course-label/CourseLabel'
import CreditPointsTag from 'components/planner/mapper/blocks/credit-points-tag/CreditPointsTag'
import AosTag from 'components/planner/student-details/area-of-study/AosTag/AosTag'
import React from 'react'
import { isPass } from 'utils/data/grades'
import { capitalise, convertLinks } from 'utils/transforms/string-transforms'
import c from './view-plan.module.scss'
import VNote from './VNote'

const VBlock = ({ block }) => {
  // status

  const status = () => {
    if (block.status === 'COMPLETED')
      return (
        <div className={!isPass(block.grade) ? c.gradedFail : c.gradedPass}>
          <span>Graded</span>
          <span>{block.grade}</span>
        </div>
      )

    if (block.status === 'ENROLLED')
      return <div className={c.enrolled}>Enrolled</div>
    if (block.status === 'NOT OFFERED')
      return <div className={c.notOffered}>Not offered</div>
    if (block.status === 'SUGGESTED')
      return <div className={c.suggested}>Suggested</div>
    if (block.status === 'DISCONTIN')
      return <div className={c.discontinued}>Discontinued</div>
    if (block.status === 'DUPLICATE')
      return <div className={c.duplicate}>Duplicate</div>
    if (block.status === 'UNCONFIRM')
      return <div className={c.unconfirm}>Unconfirm</div>
    if (block.status === 'INVALID')
      return <div className={c.invalid}>Invalid</div>

    //Credit

    if (block.grantingStatus === 'GRANTED') {
      return (
        <div className={c.granted}>
          {capitalise(block.category)} {'\u2013'} granted
        </div>
      )
    }
    if (block.grantingStatus === 'APPROVED') {
      return (
        <div className={c.granted}>
          {capitalise(block.category)} {'\u2013'} approved
        </div>
      )
    }
  }

  // class name

  const cName = () => {
    const list = [c.block]

    if (block.origin === 'CREDIT') list.push(c.credit)
    if (block.status === 'NEW') list.push(c.newBlock)
    if (block.status === 'SUGGESTED') list.push(c.suggested)
    if (block.status === 'COMPLETED') list.push(c.graded)
    if (block.status === 'ENROLLED') list.push(c.enrolled)
    if (block.blockType === 'COMMENT') list.push(c.comment)
    if (block.elective) list.push(c.comment)

    return list.join(' ')
  }
  // render

  return (
    <div
      className={c.blockContainer}
      style={{
        gridColumn: block.size
          ? block.size === 4
            ? `1 / -1`
            : `span ${block.size}`
          : `span 1`,
      }}>
      <div className={[c.block, cName()].join(' ')}>
        {/* course label */}
        {block.courseLabel && (
          <CourseLabel
            origin={block.origin}
            blockType={block.blockType}
            label={block.courseLabel}
          />
        )}
        {block.name ? <h5>{block.name}</h5> : null}
        <div className={c.description}>
          {convertLinks(block.description) ||
            `Level ${block.unitLevel} ${capitalise(
              block.disciplineDescription
            )}`}
        </div>
        {block.aos?.length ? (
          <ul className={c.aos}>
            {block.aos
              .filter((item) => item.name?.slice(0, 2) !== 'PR')
              .map((aos, i) => {
                return (
                  <AosTag key={i} aos={aos} noClick>
                    {aos.code || aos}
                  </AosTag>
                )
              })}
          </ul>
        ) : null}
        {/* credit points tag */}
        <div className={c.tags}>
          <CreditPointsTag
            blockType={block.blockType}
            origin={block.origin}
            creditPoints={block.creditPoints}
            studentView={true}
          />
        </div>
        {/* status */}
        {block.status || block.grantingStatus ? (
          <div className={c.status}>{status()}</div>
        ) : null}
      </div>

      {/* note */}
      {block.note && <VNote note={block.note} />}
    </div>
  )
}

export default VBlock
